var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "c-row",
        { staticClass: "mt-3" },
        [
          _c(
            "c-col",
            { staticClass: "col-12 col-md-6 order-1 order-md-2" },
            [
              _vm.loading ? _c("preloader", { staticClass: "mt-5" }) : _vm._e(),
              !_vm.loading && _vm.claim
                ? [
                    _c(
                      "back",
                      {
                        staticClass: "mt-3 text-gray-300",
                        attrs: { name: "claims-list" }
                      },
                      [_vm._v("Назад к списку")]
                    ),
                    _c(
                      "div",
                      { staticClass: "mb-4 f-18 text-gray-800 fw-500" },
                      [
                        _vm._v(
                          _vm._s(_vm.getProblemType(_vm.claim.ProblemType)) +
                            " №" +
                            _vm._s(_vm.claim.ILID)
                        )
                      ]
                    ),
                    _c("div", { staticClass: "mt-1 f-16 text-gray-800" }, [
                      _vm._v(_vm._s(_vm.claim.CrtDate))
                    ]),
                    _c("div", { staticClass: "mt-1 f-14 text-gray-400" }, [
                      _vm._v("Плательщик: " + _vm._s(_vm.claim.PayerName))
                    ]),
                    _c("div", { staticClass: "mt-1 f-14 text-gray-400" }, [
                      _vm._v("Описание: " + _vm._s(_vm.claim.ILDescription))
                    ]),
                    _vm.claim.ILWebSolution
                      ? _c("div", { staticClass: "mt-1 f-14 text-gray-800" }, [
                          _vm._v("Ответ: " + _vm._s(_vm.claim.ILWebSolution))
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "mt-1 f-14 text-gray-800" }, [
                      _vm._v("Статус: " + _vm._s(_vm.claim.StatusName))
                    ]),
                    _vm.claim.CloseDate
                      ? _c("div", { staticClass: "mt-1 f-14 text-gray-800" }, [
                          _vm._v(
                            "Дата завершения: " + _vm._s(_vm.claim.CloseDate)
                          )
                        ])
                      : _vm._e(),
                    _vm.claim.IWClaimNumber
                      ? _c("div", { staticClass: "mt-1 f-14 text-gray-800" }, [
                          _vm._v(
                            "Входящий номер: " + _vm._s(_vm.claim.IWClaimNumber)
                          )
                        ])
                      : _vm._e(),
                    _vm.claim.IWInputAmount
                      ? _c("div", { staticClass: "mt-1 f-14 text-gray-800" }, [
                          _vm._v(
                            "Заявленная сумма ущерба: " +
                              _vm._s(_vm.claim.IWInputAmount)
                          )
                        ])
                      : _vm._e(),
                    _vm.claim.ResultName
                      ? _c("div", { staticClass: "mt-1 f-14 text-gray-800" }, [
                          _vm._v(
                            "Результат рассмотрения: " +
                              _vm._s(_vm.claim.ResultName)
                          )
                        ])
                      : _vm._e(),
                    _vm.claim.InputDate
                      ? _c("div", { staticClass: "mt-1 f-14 text-gray-800" }, [
                          _vm._v(
                            "Дата поступления в юридический отдел: " +
                              _vm._s(_vm.claim.InputDate)
                          )
                        ])
                      : _vm._e(),
                    _vm.claim.CompensationType
                      ? _c("div", { staticClass: "mt-1 f-14 text-gray-800" }, [
                          _vm._v(
                            "Тип возмещения ущерба: " +
                              _vm._s(_vm.claim.CompensationType)
                          )
                        ])
                      : _vm._e(),
                    _vm.claim.InsuranceCompName
                      ? _c("div", { staticClass: "mt-1 f-14 text-gray-800" }, [
                          _vm._v(
                            "Страховая компания: " +
                              _vm._s(_vm.claim.InsuranceCompName)
                          )
                        ])
                      : _vm._e(),
                    _vm.claim.IWOutAmount
                      ? _c("div", { staticClass: "mt-1 f-14 text-gray-800" }, [
                          _vm._v(
                            "Сумма возмещения за счет средств Скиф-Карго: " +
                              _vm._s(_vm.claim.IWOutAmount)
                          )
                        ])
                      : _vm._e(),
                    _vm.claim.IWOutInsurAmount
                      ? _c("div", { staticClass: "mt-1 f-14 text-gray-800" }, [
                          _vm._v(
                            "Сумма возмещения за счет средств страховой компании: " +
                              _vm._s(_vm.claim.IWOutInsurAmount)
                          )
                        ])
                      : _vm._e(),
                    _vm.claim.CompensationDate
                      ? _c("div", { staticClass: "mt-1 f-14 text-gray-800" }, [
                          _vm._v(
                            "Дата возмещения Скиф-Карго: " +
                              _vm._s(_vm.claim.CompensationDate)
                          )
                        ])
                      : _vm._e(),
                    _vm.claim.CompensationInsurDate
                      ? _c("div", { staticClass: "mt-1 f-14 text-gray-800" }, [
                          _vm._v(
                            "Дата возмещения страховой компанией: " +
                              _vm._s(_vm.claim.CompensationInsurDate)
                          )
                        ])
                      : _vm._e(),
                    _vm.claim.CompensationComp
                      ? _c("div", { staticClass: "mt-1 f-14 text-gray-800" }, [
                          _vm._v(
                            "Порядок возмещения ущерба: " +
                              _vm._s(_vm.claim.CompensationComp)
                          )
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "f-18 fw-500 text-gray-800 mt-3" },
                      [_vm._v("Документы")]
                    ),
                    _c(
                      "div",
                      { staticClass: "mt-3" },
                      _vm._l(_vm.claim.Files, function(doc, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "mt-2",
                            on: {
                              click: function($event) {
                                return _vm.openDoc(doc)
                              }
                            }
                          },
                          [
                            _c(
                              "c-row",
                              { staticClass: "mx-0" },
                              [
                                _c(
                                  "c-col",
                                  {
                                    staticClass: "col-auto px-0",
                                    class: { hoverable: doc.FileLink }
                                  },
                                  [
                                    _c("c-icon-file", {
                                      attrs: { title: _vm.getFileExt(doc) }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "c-col",
                                  {
                                    staticClass:
                                      "col-md-8 pl-2 align-self-center",
                                    class: { hoverable: doc.FileLink }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "f-12 text-gray-800 text-md-gray-400"
                                      },
                                      [
                                        _vm._v(_vm._s(doc.ATName)),
                                        _c("br"),
                                        _vm._v(_vm._s(doc.FileName))
                                      ]
                                    )
                                  ]
                                ),
                                doc.FileLink
                                  ? _c(
                                      "c-col",
                                      {
                                        staticClass:
                                          "col-auto align-self-center hoverable"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "f-12 fw-500 tracking__link ml-6 mt-1"
                                          },
                                          [_vm._v("Скачать файл")]
                                        )
                                      ]
                                    )
                                  : _c(
                                      "c-col",
                                      {
                                        staticClass:
                                          "col-auto align-self-center"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "f-12 text-gray-400 mt-1"
                                          },
                                          [_vm._v("Файл отсутствует")]
                                        )
                                      ]
                                    )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      0
                    ),
                    _c(
                      "c-row",
                      { staticClass: "mt-3" },
                      [
                        _vm.fileTypes.length
                          ? _c(
                              "c-col",
                              { staticClass: "col-12 col-md-6 col-lg-8" },
                              [
                                _c("b", [_vm._v("Прикрепляемые файлы")]),
                                _vm._l(_vm.fileTypes, function(item, index) {
                                  return _c("c-row", { key: index }, [
                                    _c("div", { staticClass: "content" }, [
                                      _c("label", { staticClass: "col-lg-8" }, [
                                        _vm._v(_vm._s(item.ATName) + " "),
                                        _c("br"),
                                        _c("input", {
                                          ref: "file_" + item.ATID,
                                          refInFor: true,
                                          staticClass: "mt-2 mt-md-0",
                                          attrs: {
                                            id: "file_" + item.ATID,
                                            type: "file"
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.uploadFile(item.ATID)
                                            }
                                          }
                                        })
                                      ])
                                    ])
                                  ])
                                }),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "mt-4 btn btn-outline-primary px-4 py-2 f-14 fw-500",
                                    on: {
                                      click: function($event) {
                                        return _vm.submitFiles()
                                      }
                                    }
                                  },
                                  [_vm._v("Загрузить")]
                                )
                              ],
                              2
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }