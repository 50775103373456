<template lang="pug">
  .border.border-0.border-md-2.border-gray-200.rounded.bg-md-gray-100.py-3.px-3.text-gray-400.shadow-box.bg-white(v-loading="loading")
    //- | {{item}}
    c-row
      c-col.col-12.col-md-6
        .f-14.f-md-16.fw-500.text-gray-800
          | {{item.CurrCargoState}}
        .d-block.d-md-inline-block.text-gray-300.fw-500.mt-md-2.mb-md-3.hoverable.shadow-box.text-center(v-if="view", @click='copyOrder()')
          i.icon-file-2.mr-2.text-gray-300
          | Копировать
        .d-block.d-md-inline-block.text-gray-300.fw-500.mt-md-2.mb-md-3.ml-6.hoverable.shadow-box.text-center(v-if="view", @click='createClaim()')
          i.icon-file-2.mr-2.ml-2.text-gray-300
          | Обращение/Заявление/Претензия
        .f-12.mt-2
          i.status-dot.mr-2(:class="{'status-error': !status, 'status-success': status}")
          | {{item.PayResult}}
      c-col.text-center.text-md-right.col-12.col-md-6.bg-gray-200.bg-md-none.mt-3.mt-md-0.py-2.py-md-0
        .f-12.fw-500.text-gray-800.fa.justify-content-md-end
          .text-nowrap.fa(v-if="item.FactPlace && item.FactWeight")
            i.f-16.lh-0.icon-cargo.text-gray-300.mr-1
            | {{item.FactWeight}} кг / {{item.FactPlace}} {{item.FactPlace | plural(pluralPlaces)}}
          .text-nowrap.mr-1.ml-3.fa(v-if="item.FactVolume")
            i.f-16.lh-0.icon-fullscreen.text-gray-300.mr-1
            | {{item.FactVolume}} м
            sup
              | 3
          //.text-nowrap.ml-3.cursor-pointer(v-if="view && item.CurrCargoState === 'В обработке. Ожидайте звонка.'", @click="deleteRequest")
          //  i.icon-trash
          c-button(class="pill" v-if="view && item.CurrCargoState === 'В обработке. Ожидайте звонка.'", @click="deleteRequest")
            i.icon-trash
        .f-14.mt-2.fw-300.text-gray-800.d-none.d-md-block
          | {{item.CargoContent}}
    c-row.mt-3.flex-nowrap
      c-col.col-6.col-md-auto
        .f-10.text-muted.fw-500.text-uppercase
          | Номер заявки
        .f-12.f-md-14.mt-1.mt-md-2
          | {{item.OrderShortNo}}
      c-col.col-6.col-md-auto(v-if="item.ClientOrderNo")
        .f-10.text-muted.fw-500.text-uppercase
          | Номер заказа
        .f-12.f-md-14.mt-1.mt-md-2
          | {{item.ClientOrderNo}}
      c-col.col-6.col-md-auto(v-if="item.ClientDocsNo && item.ClientDocsNo.length")
        div(@mouseover="showDocs", @mouseleave="showDocsNo = false")
          .f-10.text-muted.fw-500.text-uppercase
            | Номер сопр документов
          .f-12.f-md-14.mt-1.mt-md-2()
            | {{docsNo[0]}}
            span(v-if="docsNo.length > 1")
              span(v-if="!showDocsNo")
                |  ▼
              span(v-else)
                |  ▲
          .f-12.f-md-14.mt-1.mt-md-2(v-for="(docs, index) in docsNo", v-if="showDocsNo")
            span(v-if="!!index")
              | {{docs}}
    .border-top.border-2.border-gray-200.mx-n3.my-3
    c-row.mx-n3.mx-md-n2
      c-col.col-6.col-md-4.pr-0
        .f-10.text-muted.fw-500.text-uppercase
          | Отправление
        .f-14.text-gray-800.mt-2
          c-row.mx-0
            c-col.col-auto.p-0.pr-2
              | {{item.SenderTownName}}
            c-col.p-0
              c-row.m-0.mt-1.order-path
                c-col.col-auto.p-0
                  .circle
                c-col.p-0.px-1
                  .path
                c-col.col-auto.p-0
                  .circle
        .f-12.mt-1.fw-300.d-none.d-md-block
          | {{item.SenderName}}
        .f-12.mt-1.fw-300.d-none.d-md-block
          | {{item.SenderPhone}}
        .f-12.mt-1.text-gray-800
          | от {{item.CrtDate | date('DD.MM.YY')}}
      c-col.col-6.col-md-4.px-3.px-md-2
        .f-10.text-muted.fw-500.text-uppercase
          | Получение
        .f-14.text-gray-800.mt-2
          | {{item.RecipTownName}}
        .f-12.mt-1.fw-300.d-none.d-md-block
          | {{item.RecipName}}
        .f-12.mt-1.fw-300.d-none.d-md-block
          | {{item.RecipPhone}}
        .f-12.mt-1
          span.text-gray-800
            | {{item.CompleteDate | date}}
          span
            |  {{item.CompleteDate | date('HH:mm')}}
      c-col.text-center.text-md-right
        .border-top.border-2.border-gray-200.mx-n3.my-3.d-block.d-md-none
        .f-10.text-muted.fw-500.text-uppercase.d-none.d-md-block
          div(v-if="item.TotalCostType==1")
            span(class="text-primary")
              | Предварительная стоимость
            //- span
            //-   c-hint(title=" ?", v-if="item.TotalCostType==1", style="display: inline-block; text-transform: none !important; font-weight: bold;")
            //-     div
            //-       | Итоговая стоимость отображается после выставления счёта
          div(v-else)
            | Оплата

        
          
        .f-14.text-gray-800.mt-0.mt-md-2.fw-500.fw-md-400
          | {{item.TransCost | parts}} ₽
        .f-12.mt-1.fw-300.d-none.d-md-block
          | {{item.PayerName}}
    template(v-if="full")
      c-row.mx-n3.mx-md-n2.mt-3
        c-col.col-6.col-md-4.pr-0
          .f-10.text-muted.fw-500.text-uppercase
            | Забор груза
          .f-12.text-gray-800.mt-1
            | {{item.PickAddress}}
        c-col.col-6.col-md-4.px-3.px-md-2
          .f-10.text-muted.fw-500.text-uppercase
            | Доставка груза
          .f-12.text-gray-800.mt-1
            | {{item.DelivAddress}}
      c-row.mx-n2.mt-3
        c-col.col-4.pr-0
          .f-10.text-muted.fw-500.text-uppercase
            | Склад
          .f-12.text-gray-800.mt-1
            | {{item.STFName}} {{item.STSName}}
        c-col.col-4.px-2
          .f-10.text-muted.fw-500.text-uppercase
            | Склад
          .f-12.text-gray-800.mt-1
            | {{item.RTFName}} {{item.RTSName}}
    //copy-dialog(:order="item", :open="openDialog" @change="openDialog = $event")
</template>

<script>
  import api from '@/js/api'
  import copyDialog from '../../core/components/copy-dialog'
  const PLURAL_PLACES = {
    base: 'мест',
    one: 'о',
    few: 'а',
    many: '',
    other: ''
  };
  export default {
    components: {
      copyDialog
    },
    props: {
      item: [Array, Object, Boolean, String, Number],
      full: {
        type: Boolean,
        default: false
      },
      view: Boolean
    },
    data: () => ({
      pluralPlaces: PLURAL_PLACES,
      showDocsNo: false,
      loading: false,
      error: null,
      openDialog: false
    }),
    computed: {
      status() {
        return !~(this.item.PayResult || '').indexOf('не');
      },
      docsNo() {
        let arr = this.item.ClientDocsNo.split(', ')
        return arr
      }
    },
    methods: {
      showDocs () {
        if (this.docsNo.length > 1) this.showDocsNo = true
      },
      copyOrder () {
        // оставил открытие диалога, на всякий случай
        // this.openDialog = true
        localStorage.setItem('copyObject', JSON.stringify({
          order: this.item,
          copyConf: {
            direction: true,
            cargoParameters: true,
            services: true
          }
        }))
        window.open('/calculator',"_self");
      },
      createClaim() {
        this.$router.push(`/claims/create?orderNo=${this.item.OrderNo}&orderShortNo=${this.item.OrderShortNo}`) 
        //{name: "create-claim", params: {orderNo: this.item.OrderNo, orderShortNo: this.item.OrderShortNo}})
      },
      deleteRequest () {
        this.loading = true
        api.cancelOrder(this.item.OrderNo).then(res => {
          this.$router.push('/request/list');
        }, err => {
          this.error = err
        }).finally(() => {
          this.loading = false
        })
      }
    }
  };
</script>

<style lang="scss" scoped>
.cursor-pointer{
  cursor: pointer;
}
</style>