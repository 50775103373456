<template lang="pug">
  div.pb-4
    c-row.mt-3
      c-col
        .f-18.fw-500.text-gray-800
          | Обращения/Заявления/Претензии
      c-col.col-auto.floating-pane
        c-button.w-100.w-md-auto(:to="{name: 'request-list'}")
          | Выбрать заявку для создания обращения
    preloader.mt-3(v-if="loading")
    c-row.mt-4(v-else)
      c-col.col-12.col-md-8.col-lg-8.d-md-block
        paginate(:load="load", ref="list")
          template(slot="list", slot-scope="{items}")
            c-table(:data="items", v-if="items.length")
              template(slot="empty")
                | Список пуст.
              c-column(prop="ILID", label="Номер документа")
                template(slot-scope="{row}")
                  a(@click="show(row.ILID)", href="#")
                    | {{row.ILID}}
              c-column(prop="OrderShortNo", label="Номер заявки", v-if="appliedColumns.OrderShortNo")
              c-column(prop="CrtDate", label="Дата заявки", v-if="appliedColumns.CrtDate")
                template(slot-scope="{row}")
                  | {{row.CrtDate}}
              //- c-column(prop="PayerName", label="Плательщик")
              c-column(prop="ProblemType", label="Вид обращения",v-if="appliedColumns.ProblemType")
                template(slot-scope="{row}")
                  | {{getProblemType(row.ProblemType)}}
              c-column(prop="StatusName", label="Статус", v-if="appliedColumns.StatusName")
                template(slot-scope="{row}")
                  .text-nowrap
                    | {{row.StatusName}}
      c-col.col-12.col-md-4.col-lg-4
        .border.border-2.border-gray-200
          .bg-gray-100.px-3.py-3
            c-row.mx-0
              c-col.p-0.f-16.fw-500.text-gray-800
                | Настройки
              c-col.p-0.col-auto.f-12.text-gray-300
                .h-100.fa.hoverable(@click="clearFilters")
                  | Очистить все
          .p-3
            c-datepicker(placeholder="Период", v-model="filters.period", :range="true")
            //- c-select.mt-2(placeholder="Статус заявки", v-model="filters.CurrCargoState", :options="options.StatusList")
            //- c-select-list.mt-2(placeholder="Статус обращения", v-model="filters.StatusName", :options="options.StatusName")
            hr.border.mt-3.mx-n3.border-gray-200.d-none.d-md-block
            .mt-3.mb-2.f-14.f-md-12.fw-500.text-gray-800
              | Поля
            .shadow-box.mt-3.mt-md-1
              c-checkbox(v-model="columns.OrderShortNo")
                .fw-500.fw-md-400
                  | Номер заявки
            .shadow-box.mt-3.mt-md-1
              c-checkbox(v-model="columns.CrtDate")
                .fw-500.fw-md-400
                  | Дата заявки
            .shadow-box.mt-3.mt-md-1
              c-checkbox(v-model="columns.ProblemType")
                .fw-500.fw-md-400
                  | Вид обращения
            .shadow-box.mt-3.mt-md-1
              c-checkbox(v-model="columns.StatusName")
                .fw-500.fw-md-400
                  | Статус

            //- hr.border.mx-n3.border-gray-200.d-none.d-md-block
            //- .mt-3.mb-2.f-14.f-md-12.fw-500.text-gray-800
            //-   | Фильтры
            //- c-select.mt-1(placeholder="Статус", v-model="filters.CurrCargoState", :options="options.StatusList", v-if="columns.CurrCargoState", :clearable="true")
            //- c-select.mt-1(placeholder="Город отправки", v-model="filters.SenderTownName", :options="options.SenderTownNameList", v-if="columns.SenderTownName", :clearable="true")
            //- c-select.mt-1(placeholder="Город получения", v-model="filters.RecipTownName", :options="options.RecipTownName", v-if="columns.RecipTownName", :clearable="true")
            //- c-select.mt-1(placeholder="Наименование груза", v-model="filters.CargoContent", :options="options.CargoContentList", v-if="columns.CargoContent", :clearable="true")
            //- c-select.mt-1(placeholder="Адрес забора", v-model="filters.PickAddress", :options="options.PickAddressList", v-if="columns.PickAddress", :clearable="true")
            //- c-select.mt-1(placeholder="Адрес доставки", v-model="filters.DelivAddress", :options="options.DelivAddressList", v-if="columns.DelivAddress", :clearable="true")
            //- c-select.mt-1(placeholder="Отправитель", v-model="filters.SenderName", :options="options.SenderList", v-if="columns.SenderName", :clearable="true")
            //- c-select.mt-1(placeholder="Получатель", v-model="filters.RecipName", :options="options.RecipList", v-if="columns.RecipName", :clearable="true")
            //- c-select.mt-1(placeholder="Плательщик", v-model="filters.PayerName", :options="options.PayerList", v-if="columns.PayerName", :clearable="true")
            //- c-select.mt-1(placeholder="Статус оплаты", v-model="filters.PayResult", :options="options.PayList", v-if="columns.PayResult", :clearable="true")
            
            c-button.mt-3.w-100.d-none.d-md-block(@click="applyFilters")
              | Фильтровать
            
</template>

<script>
  import _ from 'lodash';
  import api from '@/js/api';

  export default {
    data: () => ({
      items: [],
      error: null,
      options: {
        // StatusList: ['asdf','fwqefew']
      },
      filters: {
        period: null,
        CurrCargoState: null,
        StatusName: null
      },
      appliedFilters: {},
      appliedColumns: {},
      columns: { // ILID,OrderShortNo,CrtDate,ProblemType,StatusName
        OrderShortNo: true,
        CrtDate: true,
        ILID: true,
        ProblemType: true,
        StatusName: true,
      },
      groupBy: {
        SenderTownName: false,
      },
      loading: true,
      loadingReport: false
    }),
    provide() {
      return {validation: this.$v};
    },
    methods: {
      show(claimId) {
        this.$router.push({name: 'claims-view', params: {id: claimId, filters: this.appliedFilters}});
      },
      getProblemType(problemId) {
        let problems = {1: 'Обращение', 2: 'Заявление', 3: 'Претензия'}
        return problems[problemId]
      },
      getFilters() {
        console.log('test')
        let baseFilters = this.appliedFilters;
        let filters = _.fromPairs(_.toPairs(_.omit(baseFilters, ['period'])).filter(value => value[1]));
        let StartDate = _.get(baseFilters, 'period[0]');
        let EndDate = _.get(baseFilters, 'period[1]');
        
        if (StartDate) {
          StartDate.setHours(StartDate.getHours() - StartDate.getTimezoneOffset()/60)
          filters.StartDate = StartDate;
        }
        if (EndDate) {
          EndDate.setHours(EndDate.getHours() - EndDate.getTimezoneOffset()/60)
          filters.EndDate = EndDate;
        }
        return filters;
      },
      getGroupBy() {
        return _.toPairs(this.columns)
          .filter(item => item[1])
          .map(item => [item[0], this.groupBy[item[0]]])
          .filter(item => item[1])
          .map(item => item[0]);
      },
      load(page = 1) {
        let filters = this.getFilters();
        // let groupBy = this.getGroupBy();
        // console.log(groupBy);
        // if (groupBy.length) {
          // filters.groupBy = groupBy.join(',');
        // }
        if (filters.CurrCargoState) {
          if (filters.CurrCargoState.length) {
            filters.CurrCargoState = filters.CurrCargoState.map(item => item.value).join(',');
          } else {
            delete filters.CurrCargoState;
          }
        }

        return api.getClaims(filters)
          .then(response => {
            console.log(response);
            let items = _.get(response, 'data') || [];

            return {
              items,
            };
          }, err => {
            if (err.response.status === 504) {
              this.$notify({
                group: 'foo',
                type: 'error',
                duration: -1,
                title: 'Ошибка',
                text: 'Сервер временно недоступен'
              })
            }
          });
      },
      applyFilters() {
        this.appliedColumns = _.cloneDeep(this.columns);
        this.appliedFilters = _.cloneDeep(this.filters);
        this.$refs.list.reload();
      },
      clearFilters() {
        _.each(this.filters, (value, key) => this.filters[key] = null);
        _.each(this.groupBy, (value, key) => this.groupBy[key] = false);
      },
    },
    mounted() {
      this.appliedColumns = _.cloneDeep(this.columns);
      this.appliedFilters = _.cloneDeep(this.filters);
      var that = this
      Promise.all([
        api.getClaims().then(response => _.get(response, 'data') || {}),
      ]).then(([dics]) => {
        this.$notify({
          group: 'foo',
          clean: true
        })

        var statuses = _.map(dics, (claim, index) => {
          return {title: claim.StatusName}
        })
        this.options["StatusName"] = statuses
        this.loading = false;
      }, err => {
        if (err.response.status === 504) {
          this.$notify({
                group: 'foo',
                type: 'error',
                duration: -1,
                title: 'Ошибка',
                text: 'Сервер временно недоступен'
              })
        }
      });
    }
  }
</script>