var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [
      _c(
        "div",
        { ref: "head", staticClass: "c-tabs__head w-100 w-md-auto" },
        [
          _vm._l(_vm.labels, function(label, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "c-tabs__label text-center w-50 w-md-auto",
                class: {
                  active: _vm.value == index,
                  "text-muted": _vm.value != index
                },
                on: {
                  click: function($event) {
                    return _vm.select(index)
                  }
                }
              },
              [
                _vm._t("label-" + index, function() {
                  return [_vm._v("\n          " + _vm._s(label) + "\n        ")]
                })
              ],
              2
            )
          }),
          _vm._v(" "),
          _c("div", { staticClass: "c-tabs__line" }, [
            _c("div", {
              staticClass: "c-tabs__line__active",
              style: {
                transform: "translate(" + _vm.left + "px, 0)",
                width: _vm.width + "px"
              }
            })
          ])
        ],
        2
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "c-tabs__container pt-3" },
      _vm._l(_vm.labels, function(label, index) {
        return _vm.value == index
          ? _c("div", { key: index }, [_vm._t("tab-" + index)], 2)
          : _vm._e()
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }