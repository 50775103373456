var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "labeled",
    {
      staticClass: "d-block c-select__cont",
      class: { "is-invalid": _vm.isInvalid },
      attrs: { label: _vm.label }
    },
    [
      _c("div", { staticClass: "cont" }, [
        _vm.suffix
          ? _c(
              "div",
              { staticClass: "right va" },
              [
                _vm._t("suffix", function() {
                  return [
                    _c("span", {
                      staticClass: "vam pr-3 c-input__suffix",
                      domProps: { innerHTML: _vm._s(_vm.suffix) }
                    })
                  ]
                })
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "left", class: { "flex-shrink": _vm.suffix } },
          [
            _c("c-select-ext", {
              ref: "select",
              staticClass: "c-select",
              class: { suffix: _vm.suffix },
              attrs: {
                disabled: _vm.disabled,
                options: _vm.options,
                placeholder: _vm.placeholder,
                filter: _vm.filter,
                filterable: true,
                taggable: _vm.taggable,
                pushTags: true,
                createOption: _vm.createOption,
                searchable: !!_vm.filter,
                selectOnTab: !!_vm.filter || _vm.taggable,
                clearSearchOnSelect: true,
                getOptionLabel: _vm.getOptionLabel,
                label: "title",
                index: _vm.indexName,
                clearable: _vm.clearable,
                value: _vm.value
              },
              on: { input: _vm.input }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }