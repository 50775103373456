var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass:
        "border border-0 border-md-2 border-gray-200 rounded bg-md-gray-100 py-3 px-3 text-gray-400 shadow-box bg-white"
    },
    [
      _c(
        "c-row",
        [
          _c("c-col", { staticClass: "col-12 col-md-6" }, [
            _c("div", { staticClass: "f-14 f-md-16 fw-500 text-gray-800" }, [
              _vm._v(_vm._s(_vm.item.CurrCargoState))
            ]),
            _vm.view
              ? _c(
                  "div",
                  {
                    staticClass:
                      "d-block d-md-inline-block text-gray-300 fw-500 mt-md-2 mb-md-3 hoverable shadow-box text-center",
                    on: {
                      click: function($event) {
                        return _vm.copyOrder()
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "icon-file-2 mr-2 text-gray-300" }),
                    _vm._v("Копировать")
                  ]
                )
              : _vm._e(),
            _vm.view
              ? _c(
                  "div",
                  {
                    staticClass:
                      "d-block d-md-inline-block text-gray-300 fw-500 mt-md-2 mb-md-3 ml-6 hoverable shadow-box text-center",
                    on: {
                      click: function($event) {
                        return _vm.createClaim()
                      }
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "icon-file-2 mr-2 ml-2 text-gray-300"
                    }),
                    _vm._v("Обращение/Заявление/Претензия")
                  ]
                )
              : _vm._e(),
            _c("div", { staticClass: "f-12 mt-2" }, [
              _c("i", {
                staticClass: "status-dot mr-2",
                class: {
                  "status-error": !_vm.status,
                  "status-success": _vm.status
                }
              }),
              _vm._v(_vm._s(_vm.item.PayResult))
            ])
          ]),
          _c(
            "c-col",
            {
              staticClass:
                "text-center text-md-right col-12 col-md-6 bg-gray-200 bg-md-none mt-3 mt-md-0 py-2 py-md-0"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "f-12 fw-500 text-gray-800 fa justify-content-md-end"
                },
                [
                  _vm.item.FactPlace && _vm.item.FactWeight
                    ? _c("div", { staticClass: "text-nowrap fa" }, [
                        _c("i", {
                          staticClass: "f-16 lh-0 icon-cargo text-gray-300 mr-1"
                        }),
                        _vm._v(
                          _vm._s(_vm.item.FactWeight) +
                            " кг / " +
                            _vm._s(_vm.item.FactPlace) +
                            " " +
                            _vm._s(
                              _vm._f("plural")(
                                _vm.item.FactPlace,
                                _vm.pluralPlaces
                              )
                            )
                        )
                      ])
                    : _vm._e(),
                  _vm.item.FactVolume
                    ? _c("div", { staticClass: "text-nowrap mr-1 ml-3 fa" }, [
                        _c("i", {
                          staticClass:
                            "f-16 lh-0 icon-fullscreen text-gray-300 mr-1"
                        }),
                        _vm._v(_vm._s(_vm.item.FactVolume) + " м"),
                        _c("sup", [_vm._v("3")])
                      ])
                    : _vm._e(),
                  _vm.view &&
                  _vm.item.CurrCargoState === "В обработке. Ожидайте звонка."
                    ? _c(
                        "c-button",
                        {
                          staticClass: "pill",
                          on: { click: _vm.deleteRequest }
                        },
                        [_c("i", { staticClass: "icon-trash" })]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "f-14 mt-2 fw-300 text-gray-800 d-none d-md-block"
                },
                [_vm._v(_vm._s(_vm.item.CargoContent))]
              )
            ]
          )
        ],
        1
      ),
      _c(
        "c-row",
        { staticClass: "mt-3 flex-nowrap" },
        [
          _c("c-col", { staticClass: "col-6 col-md-auto" }, [
            _c(
              "div",
              { staticClass: "f-10 text-muted fw-500 text-uppercase" },
              [_vm._v("Номер заявки")]
            ),
            _c("div", { staticClass: "f-12 f-md-14 mt-1 mt-md-2" }, [
              _vm._v(_vm._s(_vm.item.OrderShortNo))
            ])
          ]),
          _vm.item.ClientOrderNo
            ? _c("c-col", { staticClass: "col-6 col-md-auto" }, [
                _c(
                  "div",
                  { staticClass: "f-10 text-muted fw-500 text-uppercase" },
                  [_vm._v("Номер заказа")]
                ),
                _c("div", { staticClass: "f-12 f-md-14 mt-1 mt-md-2" }, [
                  _vm._v(_vm._s(_vm.item.ClientOrderNo))
                ])
              ])
            : _vm._e(),
          _vm.item.ClientDocsNo && _vm.item.ClientDocsNo.length
            ? _c("c-col", { staticClass: "col-6 col-md-auto" }, [
                _c(
                  "div",
                  {
                    on: {
                      mouseover: _vm.showDocs,
                      mouseleave: function($event) {
                        _vm.showDocsNo = false
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "f-10 text-muted fw-500 text-uppercase" },
                      [_vm._v("Номер сопр документов")]
                    ),
                    _c("div", { staticClass: "f-12 f-md-14 mt-1 mt-md-2" }, [
                      _vm._v(_vm._s(_vm.docsNo[0])),
                      _vm.docsNo.length > 1
                        ? _c("span", [
                            !_vm.showDocsNo
                              ? _c("span", [_vm._v(" ▼")])
                              : _c("span", [_vm._v(" ▲")])
                          ])
                        : _vm._e()
                    ]),
                    _vm._l(_vm.docsNo, function(docs, index) {
                      return _vm.showDocsNo
                        ? _c(
                            "div",
                            { staticClass: "f-12 f-md-14 mt-1 mt-md-2" },
                            [
                              !!index
                                ? _c("span", [_vm._v(_vm._s(docs))])
                                : _vm._e()
                            ]
                          )
                        : _vm._e()
                    })
                  ],
                  2
                )
              ])
            : _vm._e()
        ],
        1
      ),
      _c("div", {
        staticClass: "border-top border-2 border-gray-200 mx-n3 my-3"
      }),
      _c(
        "c-row",
        { staticClass: "mx-n3 mx-md-n2" },
        [
          _c("c-col", { staticClass: "col-6 col-md-4 pr-0" }, [
            _c(
              "div",
              { staticClass: "f-10 text-muted fw-500 text-uppercase" },
              [_vm._v("Отправление")]
            ),
            _c(
              "div",
              { staticClass: "f-14 text-gray-800 mt-2" },
              [
                _c(
                  "c-row",
                  { staticClass: "mx-0" },
                  [
                    _c("c-col", { staticClass: "col-auto p-0 pr-2" }, [
                      _vm._v(_vm._s(_vm.item.SenderTownName))
                    ]),
                    _c(
                      "c-col",
                      { staticClass: "p-0" },
                      [
                        _c(
                          "c-row",
                          { staticClass: "m-0 mt-1 order-path" },
                          [
                            _c("c-col", { staticClass: "col-auto p-0" }, [
                              _c("div", { staticClass: "circle" })
                            ]),
                            _c("c-col", { staticClass: "p-0 px-1" }, [
                              _c("div", { staticClass: "path" })
                            ]),
                            _c("c-col", { staticClass: "col-auto p-0" }, [
                              _c("div", { staticClass: "circle" })
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "f-12 mt-1 fw-300 d-none d-md-block" }, [
              _vm._v(_vm._s(_vm.item.SenderName))
            ]),
            _c("div", { staticClass: "f-12 mt-1 fw-300 d-none d-md-block" }, [
              _vm._v(_vm._s(_vm.item.SenderPhone))
            ]),
            _c("div", { staticClass: "f-12 mt-1 text-gray-800" }, [
              _vm._v(
                "от " + _vm._s(_vm._f("date")(_vm.item.CrtDate, "DD.MM.YY"))
              )
            ])
          ]),
          _c("c-col", { staticClass: "col-6 col-md-4 px-3 px-md-2" }, [
            _c(
              "div",
              { staticClass: "f-10 text-muted fw-500 text-uppercase" },
              [_vm._v("Получение")]
            ),
            _c("div", { staticClass: "f-14 text-gray-800 mt-2" }, [
              _vm._v(_vm._s(_vm.item.RecipTownName))
            ]),
            _c("div", { staticClass: "f-12 mt-1 fw-300 d-none d-md-block" }, [
              _vm._v(_vm._s(_vm.item.RecipName))
            ]),
            _c("div", { staticClass: "f-12 mt-1 fw-300 d-none d-md-block" }, [
              _vm._v(_vm._s(_vm.item.RecipPhone))
            ]),
            _c("div", { staticClass: "f-12 mt-1" }, [
              _c("span", { staticClass: "text-gray-800" }, [
                _vm._v(_vm._s(_vm._f("date")(_vm.item.CompleteDate)))
              ]),
              _c("span", [
                _vm._v(
                  " " + _vm._s(_vm._f("date")(_vm.item.CompleteDate, "HH:mm"))
                )
              ])
            ])
          ]),
          _c("c-col", { staticClass: "text-center text-md-right" }, [
            _c("div", {
              staticClass:
                "border-top border-2 border-gray-200 mx-n3 my-3 d-block d-md-none"
            }),
            _c(
              "div",
              {
                staticClass:
                  "f-10 text-muted fw-500 text-uppercase d-none d-md-block"
              },
              [
                _vm.item.TotalCostType == 1
                  ? _c("div", [
                      _c("span", { staticClass: "text-primary" }, [
                        _vm._v("Предварительная стоимость")
                      ])
                    ])
                  : _c("div", [_vm._v("Оплата")])
              ]
            ),
            _c(
              "div",
              {
                staticClass: "f-14 text-gray-800 mt-0 mt-md-2 fw-500 fw-md-400"
              },
              [_vm._v(_vm._s(_vm._f("parts")(_vm.item.TransCost)) + " ₽")]
            ),
            _c("div", { staticClass: "f-12 mt-1 fw-300 d-none d-md-block" }, [
              _vm._v(_vm._s(_vm.item.PayerName))
            ])
          ])
        ],
        1
      ),
      _vm.full
        ? [
            _c(
              "c-row",
              { staticClass: "mx-n3 mx-md-n2 mt-3" },
              [
                _c("c-col", { staticClass: "col-6 col-md-4 pr-0" }, [
                  _c(
                    "div",
                    { staticClass: "f-10 text-muted fw-500 text-uppercase" },
                    [_vm._v("Забор груза")]
                  ),
                  _c("div", { staticClass: "f-12 text-gray-800 mt-1" }, [
                    _vm._v(_vm._s(_vm.item.PickAddress))
                  ])
                ]),
                _c("c-col", { staticClass: "col-6 col-md-4 px-3 px-md-2" }, [
                  _c(
                    "div",
                    { staticClass: "f-10 text-muted fw-500 text-uppercase" },
                    [_vm._v("Доставка груза")]
                  ),
                  _c("div", { staticClass: "f-12 text-gray-800 mt-1" }, [
                    _vm._v(_vm._s(_vm.item.DelivAddress))
                  ])
                ])
              ],
              1
            ),
            _c(
              "c-row",
              { staticClass: "mx-n2 mt-3" },
              [
                _c("c-col", { staticClass: "col-4 pr-0" }, [
                  _c(
                    "div",
                    { staticClass: "f-10 text-muted fw-500 text-uppercase" },
                    [_vm._v("Склад")]
                  ),
                  _c("div", { staticClass: "f-12 text-gray-800 mt-1" }, [
                    _vm._v(
                      _vm._s(_vm.item.STFName) + " " + _vm._s(_vm.item.STSName)
                    )
                  ])
                ]),
                _c("c-col", { staticClass: "col-4 px-2" }, [
                  _c(
                    "div",
                    { staticClass: "f-10 text-muted fw-500 text-uppercase" },
                    [_vm._v("Склад")]
                  ),
                  _c("div", { staticClass: "f-12 text-gray-800 mt-1" }, [
                    _vm._v(
                      _vm._s(_vm.item.RTFName) + " " + _vm._s(_vm.item.RTSName)
                    )
                  ])
                ])
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }