var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-inline-block f-0 hoverable", on: { click: _vm.click } },
    [
      _c("div", { staticClass: "fa" }, [
        _c("i", { staticClass: "icon-arrow-simple r-180 f-12 lh-0" }),
        _c(
          "div",
          { staticClass: "f-12 lh-1 ml-2" },
          [
            _vm._t("default", function() {
              return [_vm._v("Назад")]
            })
          ],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }