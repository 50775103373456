<template lang="pug">
  div
    h3
      | Бухгалтерские документы
    c-row.mt-5.mt-md-3
      c-col.col-12.col-lg-4.col-sm-6
        preloader(over, v-if="sending")
        template(v-if="!success")
          c-form
            //- c-select.mt-1(placehodler="Юридическое лицо", v-model="request.payer_id")

            c-select.mt-1.w-100.mt-md-2(placeholder="Выберите контрагента", :options= "payers_list", v-model="request.payer_id")

            c-input.mt-1(placeholder="ИНН", v-model="request.inn")
            c-input.mt-1(placeholder="КПП", v-model="request.kpp")

            c-row.mt-2.mx-n1
                c-col.px-1.col-12.col-md-6
                    c-datepicker(placeholder="Начало периода", v-model="request.start_date")
                c-col.px-1.col-12.col-md-6
                    c-datepicker(placeholder="Конец периода", v-model="request.end_date")
    
            c-input.mt-1(placeholder="Email для уведомлений", v-model="request.email")

            .alert.alert-danger.mb-0.f-12.d-block.mt-3(v-if="errors")
              div(v-for="(error, index) in errors", :key="index")
                | {{error}}
            c-button.mt-3.w-100(type="submit", :disabled="$v.request.$invalid || sending", @click="createRequest")
              | Отправить заявку

        template(v-if="success")
          .alert.alert-success.d-block
            .fw-500.mb-2
              | Заявка создана <br><a href="/buh/view">Создать еще одну</a>

</template>

<script>
  import {required, email} from 'vuelidate/lib/validators';
  import api from '@/js/api';
  export default {
    data: () => ({
      request: {
        payer_id: null,
        payer: null,
        inn: null,
        kpp: null,
        start_date: null, // this.getQuarterBounds()[0],
        end_date: null, //this.getQuarterBounds()[1],
        full_name: null,
        email: null
      },
      payers_list: [],
      sending: false,
      errors: null,
      success: false
    }),
    mounted() {
        this.request.start_date = this.getQuarterBounds()[0];
        this.request.end_date = this.getQuarterBounds()[1];

        api.getUserAgents()
                .then(({data}) => {
                  console.log(data)
                  let list = _.map(_.get(data, 'PayerList'), item => {
                    return {
                      title: item.agent_name,
                      value: item.agent_id,
                      item: item
                    }
                  });

                //   tempData.list = list
                  this.payers_list = [{title: 'Не выбран', value: 0, item: {agent_inn: '', agent_kpp: ''}}].concat(list)
                });
            
        //   resolve()
    },
    watch: {
        'request.payer_id': function(val) {
            const payer = this.payers_list.find((payer) => payer.value == val);
            // console.log(payer);
            if(payer) {
                this.request.inn = payer.item.agent_inn;
                this.request.kpp = payer.item.agent_kpp;
                this.request.email = payer.item.agent_email;
            }
        }
    },
    provide() {
      return {
        validation: this.$v
      };
    },
    validations() {
      return {
        request: {
          inn: {required},
          email: {required, email},
          start_date: {required},
          end_date: {required}
        }
      }
    },
    methods: {
        getQuarterBounds: function(date) { 
            date = date || new Date();
            return [ new Date(new Date().setMonth( date.getMonth() - date.getMonth() % 3, 1)), new Date(new Date().setMonth( date.getMonth() + 3 - date.getMonth() % 3, 0)) ]
        },
      createRequest() {
        this.sending = true;
        // let code = this.$route.params.tokenid;
        // let userData = _.pick(this.user, ['full_name', 'company', 'email', 'password', 'captcha']);
        // if (code) {
        //   userData.code = code;
        // }


        api.createAccountingRequest(this.request)
          .then(response => {
            let data = _.get(response, 'data') || {};
            this.$notify({
              group: 'foo',
              clean: true
            })
            // console.log(data);
            let errorsData = _.get(data, 'errors');
            let errors = _.isString(errorsData) ? [errorsData] : _.flatten(_.map(errorsData, item => item));
            if (errors.length) {
              this.errors = errors;
            } else {
              this.errors = null;
            }
            if (data.status == 200) {
              this.success = true;
            }
          }, err => {
            if (err.response.status === 504) {
              this.$notify({
                group: 'foo',
                type: 'error',
                duration: -1,
                title: 'Ошибка',
                text: 'Сервер временно недоступен'
              })
            }
          })
          .finally(() => {
            this.sending = false;
            // this.user.captcha = false;
            // this.$refs.captcha.reset();
          });
      }
    }
  }
</script>