<template lang="pug">
  div
    c-row.mt-3
      c-col.col-12.col-md-6.order-1.order-md-2
        preloader.mt-5(v-if="loading")
        template(v-if="!loading && claim")
          back.mt-3.text-gray-300(name="claims-list")
            | Назад к списку
          .mb-4.f-18.text-gray-800.fw-500
            | {{ getProblemType(claim.ProblemType)}} №{{ claim.ILID }}
          .mt-1.f-16.text-gray-800
            | {{claim.CrtDate}}
          .mt-1.f-14.text-gray-400
            | Плательщик: {{claim.PayerName}}
          .mt-1.f-14.text-gray-400
            | Описание: {{claim.ILDescription}}
          .mt-1.f-14.text-gray-800(v-if="claim.ILWebSolution")
            | Ответ: {{claim.ILWebSolution}}
          .mt-1.f-14.text-gray-800
            | Статус: {{claim.StatusName}}
          .mt-1.f-14.text-gray-800(v-if="claim.CloseDate")
            | Дата завершения: {{claim.CloseDate}}
          .mt-1.f-14.text-gray-800(v-if="claim.IWClaimNumber")
            | Входящий номер: {{claim.IWClaimNumber}}
          .mt-1.f-14.text-gray-800(v-if="claim.IWInputAmount")
            | Заявленная сумма ущерба: {{claim.IWInputAmount}}
          .mt-1.f-14.text-gray-800(v-if="claim.ResultName")
            | Результат рассмотрения: {{claim.ResultName}}
          .mt-1.f-14.text-gray-800(v-if="claim.InputDate")
            | Дата поступления в юридический отдел: {{claim.InputDate}}
          .mt-1.f-14.text-gray-800(v-if="claim.CompensationType")
            | Тип возмещения ущерба: {{claim.CompensationType}}

          .mt-1.f-14.text-gray-800(v-if="claim.InsuranceCompName")
            | Страховая компания: {{claim.InsuranceCompName}}
          .mt-1.f-14.text-gray-800(v-if="claim.IWOutAmount")
            | Сумма возмещения за счет средств Скиф-Карго: {{claim.IWOutAmount}}
          .mt-1.f-14.text-gray-800(v-if="claim.IWOutInsurAmount")
            | Сумма возмещения за счет средств страховой компании: {{claim.IWOutInsurAmount}}

          .mt-1.f-14.text-gray-800(v-if="claim.CompensationDate")
            | Дата возмещения Скиф-Карго: {{claim.CompensationDate}}
          .mt-1.f-14.text-gray-800(v-if="claim.CompensationInsurDate")
            | Дата возмещения страховой компанией: {{claim.CompensationInsurDate}}
          .mt-1.f-14.text-gray-800(v-if="claim.CompensationComp")
            | Порядок возмещения ущерба: {{claim.CompensationComp}}
          .f-18.fw-500.text-gray-800.mt-3
            | Документы
          .mt-3
              .mt-2(v-for="(doc, index) in claim.Files" :key="index" @click="openDoc(doc)")
                c-row.mx-0
                  c-col.col-auto.px-0(:class="{'hoverable': doc.FileLink}")
                    c-icon-file(:title="getFileExt(doc)")
                  c-col.col-md-8.pl-2.align-self-center(:class="{'hoverable': doc.FileLink}")
                    .f-12.text-gray-800.text-md-gray-400
                      | {{doc.ATName}}
                      br
                      | {{doc.FileName}}
                  c-col.col-auto.align-self-center.hoverable(v-if="doc.FileLink")
                    .f-12.fw-500.tracking__link.ml-6.mt-1
                      | Скачать файл
                  c-col.col-auto.align-self-center(v-else)
                    .f-12.text-gray-400.mt-1
                      | Файл отсутствует

          c-row.mt-3
            c-col.col-12.col-md-6.col-lg-8(v-if="fileTypes.length")
              b
                | Прикрепляемые файлы
                //- template(slot="fileTypes", slot-scope="{fileTypes}")
              c-row(v-for='(item, index) in fileTypes', :key='index')
                 .content
                    label.col-lg-8
                      | {{item.ATName}} 
                      br
                      input.mt-2.mt-md-0(:id="`file_${item.ATID}`", type="file", @change="uploadFile(item.ATID)", :ref="`file_${item.ATID}`")
              button.mt-4.btn.btn-outline-primary.px-4.py-2.f-14.fw-500(@click="submitFiles()")
                | Загрузить
          //- .my-4.f-18.text-gray-800.fw-500
          //-   | Права доступа
          //- .mt-1(v-if="user.right_to_edit_rights")
          //-   | Назначение прав пользователей
          //- .mt-1(v-if="user.right_to_create_order")
          //-   | Создание новой заявки
          //- .mt-1(v-if="user.right_to_see_order_docs")
          //-   | Доступ к документам заявки
          //- .mt-1(v-if="user.right_to_add_payer")
          //-   | Добавление плательщиков
          
</template>

<script>
  import _ from 'lodash';
  import api from '@/js/api';
  import axios from 'axios';
  import {saveAs} from 'file-saver';

  export default {
    data: () => ({
      user: null,
      loading: true,
      fileTypes: {},
      files: {}
    }),
    methods: {
      uploadFile(fileTypeId) {
        this.files[fileTypeId] = this.$refs["file_"+fileTypeId][0].files[0];
      },
      getProblemType(problemId) {
        let problems = {1: 'Обращение', 2: 'Заявление', 3: 'Претензия'}
        return problems[problemId]
      },
      openDoc(doc) {
        if (doc.FileLink) {
          axios
          .get(doc.FileLink+'/', {responseType: 'blob'})
          .then(response => {
              saveAs(response.data, doc.FileName);
          })

          // window.open(doc.FileLink + '/', '_blank');
        }
      },
      getFileExt(doc) {
        let ext = '';
        if (doc.FileName) {
          let fExt = _.last(doc.FileName.split('.'));
          if (fExt <= 4) {
            ext = fExt;
          }
        }
        return ext;
      },
      submitFiles() {
        if (!this.saving) {
            this.saving = true;

            if(this.files.length == 0) {
              this.error = "Загрузите хотя бы один файл"
              this.saving = false
            } else {
              this.error = null

              api.submitClaimFiles(this.claim.id, this.files)
                .then(({data}) => {
                  this.$notify({
                    group: 'foo',
                    clean: true
                  })
                  if (data.status == 200 && !data.error) {

                    this.$router.push({name: 'claims-list'});
                  }
                  if (data.error) {
                    this.error = data.error;
                  }
                })
                .finally(() => {
                  this.saving = false;
                });
            }
        }
      }
    },
    mounted() {
      // this.user = _.cloneDeep(this.$viewer.data);
      let params = this.$route.params;
      api.getClaim(params.id)
        .then(response => {
          this.$notify({
              group: 'foo',
              clean: true
            })
          this.claim = response.data;
          this.claim.id = this.claim.ILID

          api.getClaimsFileTypes(this.claim.ProblemType).then(response => {
            this.fileTypes = response.data
          })

        })
        .finally(() => {
          this.loading = false;
        });
    }
  };
</script>

<style lang="scss" scoped>
  .menu-item {
    &:hover {
      background-color: #fafafa;
    }
    &:first-child {
      border-radius: 4px 4px 0 0;
    }
    &:last-child {
      border-radius: 0 0 4px 4px;
    }
    & + & {
      border-top: none !important;
    }
    .icons {
      display: inline-block;
      width: 24px;
      font-size: 18px;
      line-height: 0;
    }
  }
</style>