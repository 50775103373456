var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pb-4" },
    [
      _c(
        "c-row",
        { staticClass: "mt-3" },
        [
          _c("c-col", [
            _c("div", { staticClass: "f-18 fw-500 text-gray-800" }, [
              _vm._v("Обращения/Заявления/Претензии")
            ])
          ]),
          _c(
            "c-col",
            { staticClass: "col-auto floating-pane" },
            [
              _c(
                "c-button",
                {
                  staticClass: "w-100 w-md-auto",
                  attrs: { to: { name: "request-list" } }
                },
                [_vm._v("Выбрать заявку для создания обращения")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.loading
        ? _c("preloader", { staticClass: "mt-3" })
        : _c(
            "c-row",
            { staticClass: "mt-4" },
            [
              _c(
                "c-col",
                { staticClass: "col-12 col-md-8 col-lg-8 d-md-block" },
                [
                  _c("paginate", {
                    ref: "list",
                    attrs: { load: _vm.load },
                    scopedSlots: _vm._u([
                      {
                        key: "list",
                        fn: function(ref) {
                          var items = ref.items
                          return [
                            items.length
                              ? _c(
                                  "c-table",
                                  { attrs: { data: items } },
                                  [
                                    _c("template", { slot: "empty" }, [
                                      _vm._v("Список пуст.")
                                    ]),
                                    _c("c-column", {
                                      attrs: {
                                        prop: "ILID",
                                        label: "Номер документа"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var row = ref.row
                                              return [
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: { href: "#" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.show(
                                                          row.ILID
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v(_vm._s(row.ILID))]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    }),
                                    _vm.appliedColumns.OrderShortNo
                                      ? _c("c-column", {
                                          attrs: {
                                            prop: "OrderShortNo",
                                            label: "Номер заявки"
                                          }
                                        })
                                      : _vm._e(),
                                    _vm.appliedColumns.CrtDate
                                      ? _c("c-column", {
                                          attrs: {
                                            prop: "CrtDate",
                                            label: "Дата заявки"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var row = ref.row
                                                  return [
                                                    _vm._v(_vm._s(row.CrtDate))
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      : _vm._e(),
                                    _vm.appliedColumns.ProblemType
                                      ? _c("c-column", {
                                          attrs: {
                                            prop: "ProblemType",
                                            label: "Вид обращения"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var row = ref.row
                                                  return [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getProblemType(
                                                          row.ProblemType
                                                        )
                                                      )
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      : _vm._e(),
                                    _vm.appliedColumns.StatusName
                                      ? _c("c-column", {
                                          attrs: {
                                            prop: "StatusName",
                                            label: "Статус"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var row = ref.row
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-nowrap"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(row.StatusName)
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      : _vm._e()
                                  ],
                                  2
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c("c-col", { staticClass: "col-12 col-md-4 col-lg-4" }, [
                _c("div", { staticClass: "border border-2 border-gray-200" }, [
                  _c(
                    "div",
                    { staticClass: "bg-gray-100 px-3 py-3" },
                    [
                      _c(
                        "c-row",
                        { staticClass: "mx-0" },
                        [
                          _c(
                            "c-col",
                            { staticClass: "p-0 f-16 fw-500 text-gray-800" },
                            [_vm._v("Настройки")]
                          ),
                          _c(
                            "c-col",
                            { staticClass: "p-0 col-auto f-12 text-gray-300" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "h-100 fa hoverable",
                                  on: { click: _vm.clearFilters }
                                },
                                [_vm._v("Очистить все")]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "p-3" },
                    [
                      _c("c-datepicker", {
                        attrs: { placeholder: "Период", range: true },
                        model: {
                          value: _vm.filters.period,
                          callback: function($$v) {
                            _vm.$set(_vm.filters, "period", $$v)
                          },
                          expression: "filters.period"
                        }
                      }),
                      _c("hr", {
                        staticClass:
                          "border mt-3 mx-n3 border-gray-200 d-none d-md-block"
                      }),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mt-3 mb-2 f-14 f-md-12 fw-500 text-gray-800"
                        },
                        [_vm._v("Поля")]
                      ),
                      _c(
                        "div",
                        { staticClass: "shadow-box mt-3 mt-md-1" },
                        [
                          _c(
                            "c-checkbox",
                            {
                              model: {
                                value: _vm.columns.OrderShortNo,
                                callback: function($$v) {
                                  _vm.$set(_vm.columns, "OrderShortNo", $$v)
                                },
                                expression: "columns.OrderShortNo"
                              }
                            },
                            [
                              _c("div", { staticClass: "fw-500 fw-md-400" }, [
                                _vm._v("Номер заявки")
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "shadow-box mt-3 mt-md-1" },
                        [
                          _c(
                            "c-checkbox",
                            {
                              model: {
                                value: _vm.columns.CrtDate,
                                callback: function($$v) {
                                  _vm.$set(_vm.columns, "CrtDate", $$v)
                                },
                                expression: "columns.CrtDate"
                              }
                            },
                            [
                              _c("div", { staticClass: "fw-500 fw-md-400" }, [
                                _vm._v("Дата заявки")
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "shadow-box mt-3 mt-md-1" },
                        [
                          _c(
                            "c-checkbox",
                            {
                              model: {
                                value: _vm.columns.ProblemType,
                                callback: function($$v) {
                                  _vm.$set(_vm.columns, "ProblemType", $$v)
                                },
                                expression: "columns.ProblemType"
                              }
                            },
                            [
                              _c("div", { staticClass: "fw-500 fw-md-400" }, [
                                _vm._v("Вид обращения")
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "shadow-box mt-3 mt-md-1" },
                        [
                          _c(
                            "c-checkbox",
                            {
                              model: {
                                value: _vm.columns.StatusName,
                                callback: function($$v) {
                                  _vm.$set(_vm.columns, "StatusName", $$v)
                                },
                                expression: "columns.StatusName"
                              }
                            },
                            [
                              _c("div", { staticClass: "fw-500 fw-md-400" }, [
                                _vm._v("Статус")
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "c-button",
                        {
                          staticClass: "mt-3 w-100 d-none d-md-block",
                          on: { click: _vm.applyFilters }
                        },
                        [_vm._v("Фильтровать")]
                      )
                    ],
                    1
                  )
                ])
              ])
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }