<template lang="pug">
  div.pb-4
    back.text-gray-300(name="request-list")
      | Назад к списку
    preloader.mt-5(v-if="loading")
    template(v-else)
      .f-20.fw-500.text-gray-800.mt-2
        | Заявки
      item-request.mt-3(full, :item="item" :view="true")
      .f-18.fw-500.text-gray-800.mt-3
        | Грузовые места
      el-table.mt-3.d-none.d-md-block(:data="item.Cargo", style="width: 100%", header-cell-class-name="f-10 lh-1 text-muted py-2 text-uppercase border-top border-gray-200", cell-class-name="f-14 lh-1 text-gray-800 border-gray-200")
        template(slot="empty")
          | Список пуст.
        el-table-column(prop="Weight", label="Вес грузового места, кг")
        el-table-column(prop="Volume")
          template(slot="header")
            | Объем грузового места, м
            sup
              | 3
        el-table-column(prop="dimentions", label="Размеры грузового места, см")
          template(slot-scope="scope")
            .text-nowrap
              | {{scope.row.Length}} x {{scope.row.Width}} x {{scope.row.Height}}
        el-table-column(prop="PlaceCount", label="Мест в грузовом месте")
        el-table-column(prop="Overall", label="Негабарит")
          template(slot-scope="scope")
            span(v-if="scope.row.Overall == 1")
              | да
        //- el-table-column(prop="_services", label="Услуги")
        //-   template(slot-scope="scope")
        //-     .f-14.mt-1(v-for="(service, index) in scope.row._services", :key="index")
        //-       | {{service}}
      .f-18.fw-500.text-gray-800.mt-3
        | Данные водителей
      el-table.mt-3.d-none.d-md-block(:data="item.Drivers", style="width: 100%", header-cell-class-name="f-10 lh-1 text-muted py-2 text-uppercase border-top border-gray-200", cell-class-name="f-14 lh-1 text-gray-800 border-gray-200")
        template(slot="empty")
          | Список пуст.
        el-table-column(prop="ServiceName", label="Наименование услуги")
        el-table-column(prop="DateTrans")
          template(slot="header")
            | Дата оказания услуги
        el-table-column(prop="TruckerName", label="ФИО водителя")
        el-table-column(prop="TruckName", label="Марка автомобиля")
        el-table-column(prop="TruckNo", label="Гос. номер")
          template(slot-scope="scope")
            .f-14.mt-1(v-for="(service, index) in scope.row._services", :key="index")
              | {{service}}
      div.mt-3.d-block.d-md-none
        div.m-item(v-for="(item, index) in item.Cargo", :key="index")
          c-row
            c-col.fw-500.f-12
              .fa
                i.f-20.lh-0.icon-cargo.text-gray-300.mr-2
                span
                  | {{item.Weight}} кг
            c-col.text-right.f-12
              | {{item.PlaceCount}} {{item.PlaceCount | plural(pluralPlaces)}}
          c-row.mt-3
            c-col.fw-500.f-12
              .fa
                i.f-16.lh-0.icon-fullscreen.text-gray-300.mr-2
                span.f-12
                  | {{item.Volume}} м
                  sup
                    | 3
            c-col.text-right.f-12
              | {{item.Length}} x {{item.Width}} x {{item.Height}} см
          div.mt-3
            .f-12.mt-1.text-gray-400(v-for="(service, index) in item._services", :key="index")
              | {{service}}
      .f-18.fw-500.text-gray-800.mt-3
        | Документы
      .f-14.fw-500.tracking__link.mt-1(@click="openArchive()")
        | Скачать все документы архивом
      .mt-3
        .mt-2(v-for="(doc, index) in item.Docs" :key="index" @click="openDoc(doc)")
          c-row.mx-0
            c-col.col-auto.px-0(:class="{'hoverable': doc.FileLink}")
              c-icon-file(:title="getFileExt(doc)")
            c-col.col-md-4.pl-2.align-self-center(:class="{'hoverable': doc.FileLink}")
              .f-12.text-gray-800.text-md-gray-400
                | {{doc.FileName}}
            c-col.col-auto.align-self-center.hoverable(v-if="doc.FileLink")
              //- i.icon-download.text-gray-400
              .f-12.fw-500.tracking__link.mt-1
                | Скачать файл
              //- .f-12.text-gray-300.mt-1
              //-   | {{0 | date('DD.MM.YY HH:mm')}}
            c-col.col-auto.align-self-center(v-else)
              .f-12.text-gray-400.mt-1
                | Файл отсутствует
</template>

<script>
  import 'element-ui/lib/theme-chalk/table.css';
  import 'element-ui/lib/theme-chalk/table-column.css';
  import api from '@/js/api';
  import _ from 'lodash';
  import axios from 'axios';
  import {saveAs} from 'file-saver';

  const PLURAL_PLACES = {
    base: 'мест',
    one: 'о',
    few: 'а',
    many: '',
    other: ''
  };
  const SERVICES = {
    Grid: 'обрешетка',
    VPP1: 'упаковка в воздушно-пупырчатую пленку в 1 слой',
    VPP2: 'упаковка в воздушно-пупырчатую пленку в 2 слоя',
    BoxSmall: 'упаковка в маленькие коробки',
    BoxAvg: 'упаковка в средние коробки',
    Glass: 'упаковка стекла',
    Strch: 'упаковка в стрейч-пленку',
    Palet: 'палетирование с палетой',
    PaletBP: 'палетирование без палеты',
    PackPlomb: 'упаковка в герметичные пакеты с пломбой'
  }
  export default {
    data: () => ({
      loading: true,
      item: null,
      pluralPlaces: PLURAL_PLACES
    }),
    methods: {
      openArchive() {
        let array = []
        _.forEach(this.item.Docs, item => {
          if (item.FileUID) array.push(item.FileUID)
        })
        console.log(array)
        api.getOrdersArchive(array).then(response => {
          let link = response.data.filename
          if (link && link.length) window.open(link + '/', '_blank')
        })
      },
      openDoc(doc) {
        if (doc.FileLink) {
          axios
          .get(doc.FileLink+'/', {responseType: 'blob'})
          .then(response => {
              saveAs(response.data, doc.FileName);
          })

          // window.open(doc.FileLink + '/', '_blank');
        }
      },
      getFileExt(doc) {
        let ext = '';
        if (doc.FileName) {
          let fExt = _.last(doc.FileName.split('.'));
          if (fExt <= 4) {
            ext = fExt;
          }
        }
        return ext;
      }
    },
    mounted() {
      api.getOrder(this.$route.params.id)
        .then(response => {
          let data = _.get(response, 'data') || {};
          if (data) {
            // data.Cargo[0].Grid = 1
            // data.Cargo[0].VPP1 = 1
            data.Cargo = _.map(data.Cargo, item => {
              item._services = _.map(SERVICES, (v, key) => item[key] ? v : null).filter(i => i)
              return item
            });
          }
          this.item = data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  };
</script>

<style lang="scss" scoped>
  .m-item {
    border-top: 1px solid #e5e6e8;
    border-bottom: 1px solid #e5e6e8;
    padding: 15px 0;
    & + & {
      border-top: none;
    }
  }
  .el-table td div{
    word-break: break-word;
  }
</style>
