var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "back",
        { staticClass: "mt-3 text-gray-300", attrs: { name: "claims-list" } },
        [_vm._v("Назад к списку")]
      ),
      [
        _c("div", { staticClass: "f-18 fw-500 text-gray-800 mt-2" }, [
          _vm._v("Создание документа")
        ]),
        _c("c-row", { staticClass: "mt-3" }, [
          _vm.claim.ProblemType == 1
            ? _c("b", [
                _vm._v(
                  "Cрок ответа на обращения 1 рабочий день.  Ответ предоставляется в  пн-пт 9.00-18.00"
                )
              ])
            : _vm._e(),
          _vm.claim.ProblemType == 2
            ? _c("b", [
                _vm._v(
                  "Спасибо за Ваше обращение! Ваш запрос будет обработан в течение рабочего дня с 9.00 до 18.00"
                ),
                _c("br"),
                _c(
                  "a",
                  { attrs: { href: "https://www.skif-cargo.ru/documents/4" } },
                  [_vm._v("Образцы заявлений")]
                )
              ])
            : _vm._e(),
          _vm.claim.ProblemType == 3
            ? _c("b", [
                _vm._v(
                  "Решение по претензии будет предоставлено при наличии документов, подтверждающих стоимость и размер ущерба"
                ),
                _c("br"),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://www.skif-cargo.ru/documents/8",
                      target: "_blank"
                    }
                  },
                  [
                    _vm._v(
                      "Подсказка по документам для претензии и бланки для скачивания "
                    )
                  ]
                )
              ])
            : _vm._e()
        ]),
        _c(
          "c-row",
          { staticClass: "mt-3" },
          [
            _c(
              "c-col",
              { staticClass: "col-12 col-md-6 col-lg-4" },
              [
                _c(
                  "c-select",
                  {
                    attrs: {
                      placeholder: "Тип обращения",
                      options: _vm.options.claimTypes
                    },
                    model: {
                      value: _vm.claim.ProblemType,
                      callback: function($$v) {
                        _vm.$set(_vm.claim, "ProblemType", $$v)
                      },
                      expression: "claim.ProblemType"
                    }
                  },
                  [
                    _c("option", { attrs: { value: "1" } }, [
                      _vm._v("Обращение")
                    ]),
                    _c("option", { attrs: { value: "2" } }, [
                      _vm._v("Заявление на изменение данных")
                    ]),
                    _c("option", { attrs: { value: "3" } }, [
                      _vm._v("Претензия")
                    ]),
                    _c("c-input", {
                      staticClass: "mt-2 mt-md-0 hidden",
                      attrs: { placeholder: "Номер заявки", type: "hidden" },
                      model: {
                        value: _vm.claim.OrderNo,
                        callback: function($$v) {
                          _vm.$set(_vm.claim, "OrderNo", $$v)
                        },
                        expression: "claim.OrderNo"
                      }
                    })
                  ],
                  1
                ),
                _c("c-input", {
                  staticClass: "mt-2 mt-md-0",
                  attrs: { placeholder: "Номер заявки", disabled: "disabled" },
                  model: {
                    value: _vm.claim.OrderShortNo,
                    callback: function($$v) {
                      _vm.$set(_vm.claim, "OrderShortNo", $$v)
                    },
                    expression: "claim.OrderShortNo"
                  }
                }),
                _c("c-textarea", {
                  attrs: { placeholder: "Описание проблемы" },
                  model: {
                    value: _vm.claim.ILDescription,
                    callback: function($$v) {
                      _vm.$set(_vm.claim, "ILDescription", $$v)
                    },
                    expression: "claim.ILDescription"
                  }
                }),
                _c("c-input", {
                  staticClass: "mt-2 mt-md-0",
                  attrs: { placeholder: "E-mail" },
                  model: {
                    value: _vm.claim.Email,
                    callback: function($$v) {
                      _vm.$set(_vm.claim, "Email", $$v)
                    },
                    expression: "claim.Email"
                  }
                }),
                _c("c-input", {
                  directives: [
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: { mask: "+99999999999" },
                      expression: "{mask: '+99999999999'}"
                    }
                  ],
                  staticClass: "mt-2 mt-md-0",
                  attrs: { placeholder: "Телефон" },
                  model: {
                    value: _vm.claim.Phone,
                    callback: function($$v) {
                      _vm.$set(_vm.claim, "Phone", $$v)
                    },
                    expression: "claim.Phone"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "c-row",
          { staticClass: "mt-3" },
          [
            _vm.fileTypes.length
              ? _c(
                  "c-col",
                  { staticClass: "col-12 col-md-6 col-lg-8" },
                  [
                    _c("b", [_vm._v("Прикрепляемые файлы")]),
                    _vm._l(_vm.fileTypesDict, function(group, group_index) {
                      return _c(
                        "c-row",
                        { key: _vm.index },
                        _vm._l(group, function(item, index) {
                          return _vm.fileTypes.length
                            ? _c(
                                "c-row",
                                {
                                  key: index,
                                  staticClass: "mt-3",
                                  class: {
                                    hidden: _vm.claim.ProblemType != group_index
                                  },
                                  attrs: { id: "files_" + group_index }
                                },
                                [
                                  _c("div", { staticClass: "content" }, [
                                    _c("label", { staticClass: "col-lg-8" }, [
                                      item.MustHave == 1
                                        ? _c("span", { staticClass: "red" }, [
                                            _vm._v(_vm._s(item.ATName) + " ")
                                          ])
                                        : _c("span", [
                                            _vm._v(_vm._s(item.ATName))
                                          ]),
                                      _c("br"),
                                      _c("input", {
                                        ref: "file_" + item.ATID,
                                        refInFor: true,
                                        staticClass: "mt-2 mt-md-0",
                                        attrs: {
                                          id: "file_" + item.ATID,
                                          type: "file"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.uploadFile(item.ATID)
                                          }
                                        }
                                      })
                                    ])
                                  ])
                                ]
                              )
                            : _vm._e()
                        }),
                        1
                      )
                    })
                  ],
                  2
                )
              : _vm._e()
          ],
          1
        ),
        _c(
          "c-row",
          { staticClass: "mt-3" },
          [
            _c(
              "c-col",
              { staticClass: "col-12 col-md-6 col-lg-4" },
              [
                _vm.error
                  ? _c(
                      "div",
                      {
                        staticClass: "alert alert-danger mb-0 f-12 d-block mt-3"
                      },
                      [_vm._v(_vm._s(_vm.error))]
                    )
                  : _vm._e(),
                _c(
                  "c-button",
                  {
                    staticClass: "mt-4 w-100 w-md-auto",
                    attrs: { disabled: _vm.saving },
                    on: { click: _vm.save }
                  },
                  [_vm._v(" Сохранить")]
                )
              ],
              1
            )
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }