<template lang="pug">
  .page
    container.calc.pt-md-2.pb-4
      //- preloader(over, v-if="sending")
      c-row(v-if="loaded")
        c-col.col-12.col-md-8
          c-row.blocks-list(:class="{authorized: $viewer.logged}")
            c-col.col-12.block(:class="{'text-muted': !blocks.calc.filled}")
              div(:class="{'d-none d-md-inline-block': !blocks.calc.show}")
                h3(:class="{'text-muted': !blocks.calc.filled}")
                  span.d-inline-block.mr-3
                    | Направление и параметры груза
                  div.mb-2.d-inline-block.text-primary.f-14.hoverable(v-if="!blocks.calc.show && blocks.calc.filled", @click="editBlock('calc')")
                    i.icon-pencil.mr-2
                    span(v-if="!isBlockInvalid('calc')")
                      | Редактировать
                    span(v-if="isBlockInvalid('calc')")
                      | Продолжить заполнение
                div.mt-2.text-purple.fw-500(v-if="!blocks.calc.show && blocks.calc.filled && isBlockInvalid('calc')")
                  i.icon-warning.mr-2
                  | Не все данные заполнены
              div.d-block.d-md-none(v-if='!blocks.calc.show && blocks.calc.filled', @click="editBlock('calc')")
                .w-100.btn.btn-outline-primary.py-2.px-4.text-left
                  c-row.flex-nowrap.align-items-center
                    c-col.f-14.fw-500
                      | Направление
                    c-col.col-auto
                      i.icon-angle-down.f-10
                div.py-2.bg-gray-200.text-gray-400.fa.justify-content-center(v-if="!blocks.calc.show && blocks.calc.filled && isBlockInvalid('calc')")
                  i.icon-warning.bg-gray-400.mr-2
                  | Не все данные заполнены
              div.mt-4.p-relative(v-if='blocks.calc.show')
                // preloader(over)
                c-row
                  c-col.col-12.col-md
                    c-select(placeholder="Откуда", :options="options.cities", v-model="calc.city_from_id", :filter="filterCities", :getOptionLabel="getCityLabel")
                  c-col.px-3.col-12.col-md-auto.px-md-0.text-center
                    .fa.my-3.my-md-0.justify-content-center.shadow-box.py-2.py-md-0(@click='swapLocation()')
                      .d-inline-block.button-swap.va
                        i.icon-swap.text-primary.f-16.vam
                      span.text-primary.fw-500.d-inline-block.d-md-none.ml-2.f-12
                        | Поменять местами
                  c-col.col-12.col-md-6
                    c-select(placeholder="Куда", :options="options.cities", v-model="calc.city_to_id", :filter="filterCities", :getOptionLabel="getCityLabel")
                c-row(v-if="response.is_calc_express")
                  c-col
                    .shadow-box.mt-3.mt-md-0.p-relative
                      c-checkbox.mt-md-3(v-model='calc.express_service', :disabled="response.express_service_cost == null")
                        .fa
                          span.fw-500.fw-md-400
                            | Экспресс доставка
                          c-tip.tip-express.ml-2.d-none.d-md-inline-block
                            template(slot="subject")
                              i.icon-help.text-gray-300.f-16.lh-0
                            div
                              | Ускоренный график при условии:
                            div
                              | Забор груза до 15:00
                            div
                              | Сдача груза до 18:00 на склад филиала отправления
                            //- div
                            //-   | Передача груза водителю до 12:00
                          span.text-gray-400.ml-2(v-if="response.express_service_cost")
                            | + {{response.express_service_cost | abs | parts}} ₽
                      .d-block.d-md-none
                        .separator.solid.my-3.shadow-inside
                        c-hint(title="ЧТО ЭТО?")
                          div
                            | Ускоренный график при условии:
                          div
                            | Забор груза до 15:00
                          div
                            | Сдача груза до 18:00 на склад филиала отправления
                c-row
                  c-col.col-12.col-md-6
                    div.f-18.f-md-14.text-gray-800.text-md-muted.fw-500.my-3
                      | Отправление
                    .shadow-box.mt-2(:class="{active: calc.cargo_pickup == 0}")
                      c-radio(v-model='calc.cargo_pickup', :type="0", :disabled="options.officesFrom && !options.officesFrom.length || getTownInfo(calc.city_from_id).is_filial == false")
                        span.fw-500.fw-md-400
                          | Сдать груз в отделение
                    .shadow-box.mt-2(:class="{active: calc.cargo_pickup == 1}")
                      c-radio(v-model='calc.cargo_pickup', :type="1")
                        span.fw-500.fw-md-400
                          | Забрать по адресу 
                          span.d-block.d-md-inline-block.text-gray-300.fw-400.mt-2.mt-md-0(v-if="response.pickup_service_cost")
                            | + {{response.pickup_service_cost | abs | parts}} ₽
                    c-select.mt-4.mt-md-2(v-if="!calc.cargo_pickup", placeholder="Адрес отделения", :options='options.officesFrom', v-model='calc.cargo_pickup_id')
                    c-input.mt-2(v-else, placeholder='Адрес', v-model='calc.cargo_pickup_adress')
                    c-row.mt-2.mx-n1
                      c-col.px-1.col-12.col-md-6
                        c-datepicker(placeholder="Дата", v-model="calc.cargo_pickup_date", :dates="options.datesFrom")
                        //- c-select(placeholder='Дата', :options='options.datesFrom', v-model='calc.cargo_pickup_date', :suffix="true")
                        //-   template(slot="suffix")
                        //-     i.icon-calendar.vam.mr-2
                      c-col.px-1.col-12.col-md-6(v-if="calc.cargo_pickup == 1")
                        c-select.mt-2.mt-md-0(placeholder='Время', :options='options.timeFrom', v-model='calc.cargo_pickup_time', :suffix="true", :taggable="true", :filter="filterTime", :value-as-title="true",  v-mask="{alias: 'datetime', inputFormat: 'HH:MM-HH:MM', placeholder: '_', showMaskOnFocus: false}")
                          template(slot="suffix")
                            i.icon-clock.vam.mr-2.text-gray-300
                    div.mt-2.text-gray-300.f-14(v-if="response.pickup_time_service_cost && calc.cargo_pickup == 1")
                      | Доставка ко времени + {{response.pickup_time_service_cost | abs | parts}} ₽

                    c.row.mt-2.mx-n1(v-if="calc.cargo_pickup== 1")
                      c-col.px-1.col-12.text-gray-800
                        c-textarea( placeholder="Требования для водителя или грузчика" v-model='calc.pickup_comment')
                  c-col.col-12.col-md-6
                    div.f-18.f-md-14.text-gray-800.text-md-muted.fw-500.my-3
                      | Получение
                    .shadow-box.mt-2(:class="{active: calc.cargo_delivery == 0}")
                      c-radio(v-model='calc.cargo_delivery', :type="0", :disabled="options.officesTo && !options.officesTo.length || getTownInfo(calc.city_to_id).is_filial == false")
                        span.fw-500.fw-md-400
                          | Получить груз в отделении
                    .shadow-box.mt-2(:class="{active: calc.cargo_delivery == 0}")
                      c-radio(v-model='calc.cargo_delivery', :type="1")
                        | Доставить по адресу 
                        span.d-block.d-md-inline-block.text-gray-300.fw-400.mt-2.mt-md-0(v-if="response.delivery_service_cost")
                          | + {{response.delivery_service_cost | abs | parts}} ₽
                    c-select.mt-2(v-if="!calc.cargo_delivery", placeholder="Адрес отделения", :options='options.officesTo', v-model='calc.cargo_delivery_id')
                    c-input.mt-2(v-else, placeholder='Адрес', v-model='calc.cargo_delivery_adress')
                    c-row.mt-2.mx-n1
                      c-col.px-1.col-12.col-md-6
                        c-datepicker(placeholder="Дата", v-model="calc.cargo_delivery_date", :dates="options.datesTo")
                        //- c-select(placeholder='Дата', :options='options.datesTo', v-model='calc.cargo_delivery_date', :suffix="true")
                        //-   template(slot="suffix")
                        //-     i.icon-calendar.vam.mr-2
                      c-col.px-1.col-12.col-md-6(v-if="calc.cargo_delivery == 1")
                        c-select.mt-2.mt-md-0(placeholder='Время', :options='options.timeTo', v-model='calc.cargo_delivery_time', :suffix="true", :taggable="true", :filter="filterTime", :value-as-title="true", v-mask="{alias: 'datetime', inputFormat: 'HH:MM--HH:MM', placeholder: '_', showMaskOnFocus: false}")
                          template(slot="suffix")
                            i.icon-clock.vam.mr-2.text-gray-300
                    div.mt-2.text-gray-300.f-14(v-if="response.delivery_time_service_cost && calc.cargo_delivery == 1")
                      | Доставка ко времени + {{response.delivery_time_service_cost | abs | parts}} ₽
                    c.row.mt-2.mx-n1(v-if="calc.cargo_delivery == 1")
                      c-col.px-1.col-12.text-gray-800
                        c-textarea( placeholder="Требования для водителя или грузчика" v-model='calc.delivery_comment')
                c-row.mt-4
                  c-col.col
                    div.f-18.f-md-16.fw-500
                      | Параметры груза
                    c-tab-list.mt-2(:labels="{common: 'Указать общий вес/объем', extra: 'Указать с габаритами'}", v-model="calc.calc_type")
                      template(slot='label-common')
                        .d-none.d-md-block
                          | Указать общий вес/объем
                        .d-block.d-md-none
                          | Общий вес/объем
                      template(slot='label-extra')
                        .d-none.d-md-block
                          | Указать с габаритами
                        .d-block.d-md-none
                          | Габариты
                      template(slot='tab-common')
                        c-row.mx-n1
                          c-col.px-1.col-12.col-md
                            c-input(type='number', placeholder='Вес груза', suffix='кг', v-model.number='calc.common_cargo_weight', :min="0")
                          c-col.px-1.col-12.col-md.mt-2.mt-md-0
                            c-input(type='number', placeholder='Объем груза', suffix='м<sup>3</sup>', v-model.number='calc.common_cargo_volume', :min="0")
                          c-col.px-1.col-12.col-md.mt-2.mt-md-0
                            c-input.d-none.d-md-block(type='number', placeholder='Кол-во мест', v-model.number='calc.common_cargo_amount', :min="0", :int="true")
                            c-plusminus.my-2.d-block.d-md-none(placeholder='Кол-во мест', v-model.number='calc.common_cargo_amount', :min="1")
                          c-col.px-1.col-12.col-md
                            .shadow-box.mt-3.p-relative
                              c-checkbox.ml-md-2(v-model='calc.is_overall')
                                span.fw-500.fw-md-400
                                  | Негабарит
                                  c-tip.d-none.d-md-inline-block.tip-express.ml-2.align-middle
                                    template(slot="subject")
                                      i.icon-help.ml-1.f-16.lh-0.text-gray-300
                                    div
                                      | Грузовое место попадает под одно из условий:
                                    div
                                      | Вес места > 900 кг;
                                    div
                                      | Cторона > 2,4 м;
                                    div
                                      | Д+Ш+В > 4,2 м.
                              .d-block.d-md-none
                                .separator.solid.my-3.shadow-inside
                                c-hint(title="ЧТО ЭТО?")
                                  div
                                    | Грузовое место попадает под одно из условий:
                                  div
                                    | Вес места > 900 кг;
                                  div
                                    | Cторона > 2,4 м;
                                  div
                                    | Д+Ш+В > 4,2 м.
                        c-row.mx-n1.mb-1.mt-2
                          c-col.px-1.col-12.col-md
                            span.maxcomment
                              | Габариты и вес наибольшего места                                    
                        c-row.mx-n1.mb-1
                          c-col.px-1.col-12.col-md
                            c-input(type='number', placeholder='Длина', suffix='см', v-model.number='calc.maxlength', :min="0")
                          c-col.px-1.col-12.col-md
                            c-input(type='number', placeholder='Ширина', suffix='см', v-model.number='calc.maxwidth', :min="0")
                          c-col.px-1.col-12.col-md
                            c-input(type='number', placeholder='Высота', suffix='см', v-model.number='calc.maxheight', :min="0")
                          c-col.px-1.col-12.col-md
                            c-input(type='number', placeholder='Вес', suffix='кг', v-model.number='calc.maxweight', :min="0")  
                      template(slot='tab-extra')
                        c-row.mx-n1.mb-1(v-for='(item, index) in $v.cargo.$model', :key='index')
                          c-col.px-1.col-4.col-md
                            c-input(type='number', placeholder='Длина', v-model.number='item.length', :accessor="'cargo.$each.' + index + '.length'", :min="0")
                          c-col.px-1.col-4.col-md
                            c-input(type='number', placeholder='Ширина', v-model.number='item.width', :accessor="'cargo.$each.' + index + '.width'", :min="0")
                          c-col.px-1.col-4.col-md
                            c-input(type='number', placeholder='Высота', v-model.number='item.height', :accessor="'cargo.$each.' + index + '.height'", :min="0")
                          c-col.px-1.col-6.col-md.mt-2.mt-md-0
                            c-select(placeholder='Ед. изм.', :options='options.units', v-model='item.measure_LWH', :accessor="'cargo.$each.' + index + '.measure_LWH'")
                          c-col.px-1.col-6.col-md.mt-2.mt-md-0
                            c-input(type='number', placeholder='Вес', suffix='кг', v-model.number='item.weight', :accessor="'cargo.$each.' + index + '.weight'", :min="0")
                          c-col.px-1.col-12.col-md
                            c-input.d-none.d-md-block(type='number', placeholder='Таких мест', v-model.number='item.amount', :accessor="'cargo.$each.' + index + '.amount'", :min="0", int)
                            c-plusminus.mt-3.d-block.d-md-none(placeholder='Кол-во таких мест', v-model.number='item.amount', :min="1")
                          c-col.px-1.col-12.col-md-auto.order-8.order-md-7.mt-3.mt-md-0(v-if='cargo.length > 1')
                            .add-cargo.h-100.fa.hoverable.shadow-box.p-md-2.pt-md-3.text-pink.text-md-gray-300(@click='removeCargo(index)')
                              i.icon-trash.text-pink.text-md-gray-300
                              span.ml-2.fw-500.d-md-none
                                | Удалить
                          c-col.px-1.col-12.col-md-auto.order-7.order-md-8.mt-3.mt-md-0(:class="{'d-none d-md-block': !item.is_dimensional}")
                            .pt-2.h-100.text-center.d-none.d-md-inline-block(:class="{'invisible': !item.is_dimensional}")
                              .h-100.fa
                                c-tip.tip-express.ml-2
                                  template(slot="subject")
                                    i.icon-warning.f-20.lh-0.danger.hoverable
                                  .text-left
                                    div
                                      | Негабаритный груз.
                                    div
                                      | Грузовое место попадает под одно из условий:
                                    div
                                      | Вес места > 900 кг;
                                    div
                                      | Cторона > 2,4 м;
                                    div
                                      | Д+Ш+В > 4,2 м.
                            .d-block.d-md-none.text-gray-300.shadow-box.p-relative(v-if="item.is_dimensional")
                              c-row.align-items-center
                                c-col
                                  .fa
                                    i.icon-warning.bg-gray-300.mb-1
                                    span.f-12.ml-2
                                      | Негабаритный груз
                                c-col.col-auto.p-static
                                  c-hint(title="ЧТО ЭТО?")
                                    div
                                      | Грузовое место попадает под одно из условий:
                                    div
                                      | Вес места > 900 кг;
                                    div
                                      | Cторона > 2,4 м;
                                    div
                                      | Д+Ш+В > 4,2 м.
                          c-col.col-12.order-9.d-block.d-md-none.px-1.mt-3.mb-2(v-if="$v.cargo.$model.length > 1 && index != $v.cargo.$model.length - 1")
                            .separator
                        .separator.d-block.d-md-none.my-3
                        .d-block.d-md-inline-block.text-primary.fw-500.mt-md-2.mb-md-3.hoverable.shadow-box.text-center(@click='addCargo')
                          i.icon-plus.mr-2.text-primary
                          | Добавить грузовое место
                        .separator.d-block.d-md-none.my-3
                    c-row.mt-4.mt-md-2.mx-n1
                      c-col.px-1.col-12.col-md-9
                        c-keep-alive
                          c-select(placeholder='Характер груза', :options='options.cargoKinds', v-model='calc.cargo_kind_id', :taggable="true", :filter="filterCargoType", :moptions="options.cargoKindMOptions")
                    c-row.mt-4.mt-md-2.mx-n1
                      c-col.px-1.col-12.col-md-9
                        c-keep-alive
                          c-input(type="number", placeholder='Стоимость груза', suffix='₽', v-model='calc.cargo_cost', :min="0")
                c-row.mt-4
                  c-col.col
                    div.f-18.f-md-16.fw-500
                      | Упаковка
                    c-row.mt-2.mt-md-3.mx-n1
                      c-col.px-1.mt-2.col-12.col-md-6(v-if="response.palletizing_service_cost")
                        card.shadow-box(:class="{active: calc.palletizing_service}")
                          c-checkbox.fw-500(v-model='calc.palletizing_service')
                            | Палетирование
                          .cont.mt-1
                            .text-gray-400(:class="{'text-muted': !calc.palletizing_service}")
                              //- (:class="{'text-muted': !calc.palletizing_service}")
                              //-   span(v-if="!calc.palletizing_service")
                              //-     | от 
                              span(v-if="!calc.palletizing_service")
                                | + 
                              | {{response.palletizing_service_cost | abs | parts}} ₽
                      c-col.px-1.mt-2.col-12.col-md-6(v-if="response.stretch_service_cost")
                        card.shadow-box(:class="{active: calc.stretch_service}")
                          c-checkbox.fw-500(v-model='calc.stretch_service')
                            | Стрейч пленка
                          .cont.mt-1
                            .text-gray-400(:class="{'text-muted': !calc.stretch_service}")
                              span(v-if="!calc.stretch_service")
                                | +
                              | {{response.stretch_service_cost | abs | parts}} ₽
                      c-col.px-1.mt-2.col-12.col-md-6(v-if="response.bubble_service_cost")
                        card.shadow-box(:class="{active: calc.bubble_service}")
                          c-checkbox.fw-500(v-model='calc.bubble_service')
                            | Воздушно-пузырьковая пленка
                          .cont.mt-1
                            .text-gray-400(:class="{'text-muted': !calc.bubble_service}")
                              span(v-if="!calc.bubble_service")
                                | + 
                              | {{response.bubble_service_cost | abs | parts}} ₽
                      c-col.px-1.mt-2.col-12.col-md-6(v-if="response.pallet_board_service_cost")
                        card.shadow-box(:class="{active: calc.pallet_board}")
                          c-checkbox.fw-500(v-model='calc.pallet_board')
                            | Палетный борт
                          .cont.mt-1
                            .text-gray-400(:class="{'text-muted': !calc.pallet_board}")
                              span(v-if="!calc.pallet_board")
                                | + 
                              | {{response.pallet_board_service_cost | abs | parts}} ₽
                      c-col.px-1.mt-2.col-12.col-md-6(v-if="response.other_service_cost")
                        card.shadow-box(:class="{active: calc.other_pack_service_check}")
                          c-checkbox.fw-500(v-model='calc.other_pack_service_check')
                            | Прочие услуги
                          .cont.mt-1
                            .text-gray-400(:class="{'text-muted': !calc.other_pack_service_check}")
                              span(v-if="!calc.other_pack_service_check")
                                | + 
                              | {{response.other_service_cost | abs | parts}} ₽
                            c-select.mt-2(:disabled="!calc.other_pack_service_check", placeholder="Упаковка", :options="options.packService", v-model='calc.other_pack_service')
                      c-col.px-1.mt-2.col-12.col-md-6(v-if="response.rigid_packings_service_cost")
                        card.shadow-box(:class="{active: calc.rigid_packing_service}")
                          c-checkbox.fw-500(v-model='calc.rigid_packing_service')
                            | Жесткая упаковка
                          .cont.mt-1
                            .text-gray-400(:class="{'text-muted': !calc.rigid_packing_service}")
                              span(v-if="!calc.rigid_packing_service")
                                | + 
                              | {{response.rigid_packings_service_cost | abs | parts}} ₽
                c-row.mt-4
                  c-col.col
                    div.f-18.f-md-16.fw-500
                      | Дополнительные услуги
                    c-row.mt-2.mt-md-3.mx-n1
                      c-col.px-1.mt-2.col-12.col-md-6(v-if="calc.cargo_pickup")
                        card.shadow-box(:class="{active: calc.sender_loading}")
                          c-checkbox.fw-500(v-model='calc.sender_loading')
                            | Погрузочные работы
                            c-tip.d-none.d-md-inline-block.tip-express.ml-2.align-middle
                              template(slot="subject")
                                i.icon-help.ml-1.f-16.lh-0.text-gray-300(@click="showPrr()")
                              div
                                | Нажмите для получения подробного описания услуги
                          .cont.mt-1
                            .f-12.f-md-14.text-muted
                              | У отправителя
                            .text-gray-400.mt-2(:class="{'text-muted': !calc.sender_loading}")
                              span(v-if="!calc.sender_loading")
                                | + 
                              | {{response.sender_service_loading_cost | abs | parts}} ₽
                            //- c-select.mt-2(:disabled="!calc.sender_loading", placeholder='Вес наибольшего места', :options='options.weights', v-model='calc.sender_loading_weight')
                            c-select.mt-2(:disabled="!calc.sender_loading", placeholder='Спуск/подъем на этаж', :options='options.floors', v-model='calc.sender_loading_floor')
                          //- .d-block.d-md-none
                          //-   .separator.solid.my-3.shadow-inside
                          //-   c-hint(title="УСЛОВИЯ")
                          //-     div
                          //-       | Расчет ПРР производится для ГМ
                          //-     div
                          //-       | весом < 25 кг;
                          //-     div
                          //-       | длиной < 70 см.
                      c-col.px-1.mt-2.col-12.col-md-6(v-if="calc.cargo_delivery")
                        card.shadow-box(:class="{active: calc.receiver_loading}")
                          c-checkbox.fw-500(v-model='calc.receiver_loading')
                            | Погрузочные работы
                            c-tip.d-none.d-md-inline-block.tip-express.ml-2.align-middle
                              template(slot="subject")
                                i.icon-help.ml-1.f-16.lh-0.text-gray-300(@click="showPrr()")
                              div
                                | Нажмите для получения подробного описания услуги
                          .cont.mt-1
                            .f-12.f-md-14.text-muted
                              | У получателя
                            .text-gray-400.mt-2(:class="{'text-muted': !calc.receiver_loading}")
                              span(v-if="!calc.receiver_loading")
                                | + 
                              | {{response.receiver_service_loading_cost | abs | parts}} ₽
                            //- c-select.mt-2(:disabled="!calc.receiver_loading", placeholder='Вес наибольшего места', :options='options.weights', v-model='calc.receiver_loading_weight')
                            c-select.mt-2(:disabled="!calc.receiver_loading", placeholder='Спуск/подъем на этаж', :options='options.floors', v-model='calc.receiver_loading_floor')
                          //- .d-block.d-md-none
                            //- .separator.solid.my-3.shadow-inside
                            //- c-hint(title="УСЛОВИЯ", @click="showPrr()")
                              //- div
                                //- | Нажмите для получения подробного описания услуги
                      c-col.px-1.mt-2.col-12.col-md-6(v-if="response.insurance_service_cost")
                        card.shadow-box(:class="{active: calc.insurance_service}")
                          c-checkbox.hidden.fw-500(v-model='calc.insurance_service')
                          | Страхование груза
                          .cont.mt-2
                            .text-gray-400(:class="{'text-muted': !calc.insurance_service}")
                              span(v-if="!calc.insurance_service")
                                | + 
                              | {{response.insurance_service_cost | abs | parts}} ₽
                            fieldset.mt-2(:disabled = "!calc.insurance_service")
                              c-input(type="number", placeholder='Стоимость груза', suffix='₽', v-model='calc.cargo_cost', :min="0")
                      c-col.px-1.mt-2.col-12.col-md-6(v-if="response.docs_service_cost")
                        card.shadow-box(:class="{active: calc.docs_service_check}")
                          c-checkbox.fw-500(v-model='calc.docs_service_check')
                            | Перевозка документов
                          .cont.mt-2
                            .text-gray-400(:class="{'text-muted': !calc.docs_service_check}")
                              span(v-if="!calc.docs_service_check")
                                | + 
                              | {{response.docs_service_cost | abs | parts}} ₽
                            c-row
                              c-col
                                c-select.mt-2(:disabled="!calc.docs_service_check", :options='options.docsService', v-model="calc.docs_service")
                      c-col.px-1.mt-2.col-12.col-md-6(v-if="response.thermo_service_cost && response.is_calc_termo")
                        card.shadow-box(:class="{active: calc.thermo_service}")
                          c-checkbox.fw-500(v-model='calc.thermo_service')
                            | Терморежим +5С - +25С
                          .cont.mt-2
                            .text-gray-400(:class="{'text-muted': !calc.thermo_service}")
                              span(v-if="!calc.thermo_service")
                                | + 
                              | {{response.thermo_service_cost | abs | parts}} ₽
                .mt-4.mb-4.text-center.text-md-left
                  c-button.w-100.w-md-auto(type="submit", @click="check('calc')", id="calczayavka")
                    span(v-if="blocks.sender.filled")
                      | Продолжить
                    span(v-else)
                      | Оформить заявку
                  c-button.d-none.d-md-inline-block.white.ml-3(v-if="!backlog", @click="backlog = true")
                    | Поручить оформление менеджеру
                  div.backlog.p-4.mt-4(v-if="backlog")
                    div.text-center.f-16
                      | Поручите оформление менеджеру.
                      div
                        | Укажите Ваш телефон, и мы вам обязательно перезвоним в течение 10 минут.
                    c-row.mt-3
                      c-col
                        c-input(v-model="calc.backlog_phone", subclass="large", placeholder="Телефон", v-mask="{mask: '+79999999999'}")
                      c-col.col-auto
                        c-button.backlog-button(@click="sendBacklog()", :disabled="$v.backlog.$invalid")
                          | Отправить
              div.mt-3.d-none.d-md-block(v-if='!blocks.calc.show && blocks.calc.filled')
                c-row
                  c-col.col-6
                    template(v-if="!calc.cargo_pickup")
                      .text-muted.fw-500
                        | Сдача груза в отделение
                      .mt-2.f-14
                        | {{calc.city_from_id | dic(options.citiesAll)}}, {{calc.cargo_pickup_id | dic(options.officesFrom)}}
                    template(v-if="calc.cargo_pickup")
                      .text-muted.fw-500
                        | Забрать по адресу
                      .mt-2.f-14
                        | {{calc.city_from_id | dic(options.citiesAll)}}, {{calc.cargo_pickup_adress}}
                  c-col.col-6
                    template(v-if="!calc.cargo_delivery")
                      .text-muted.fw-500
                        | Получить груз в отделении
                      .mt-2.f-14
                        | {{calc.city_to_id | dic(options.citiesAll)}}, {{calc.cargo_delivery_id | dic(options.officesTo)}}
                    template(v-if="calc.cargo_delivery")
                      .text-muted.fw-500
                        | Доставить по адресу
                      .mt-2.f-14
                        | {{calc.city_to_id | dic(options.citiesAll)}}, {{calc.cargo_delivery_adress}}
                template(v-if="calc.calc_type == 'common'")
                  .mt-3
                    span.text-muted.fw-500
                      | Груз:
                    span
                      template(v-if="calc.common_cargo_weight")
                        |  {{calc.common_cargo_weight}} кг
                      template(v-if="calc.common_cargo_volume")
                        | , {{calc.common_cargo_volume}} м
                        sup
                        | 3
                      template(v-if="calc.common_cargo_amount")
                        | , {{calc.common_cargo_amount}} место
                template(v-if="calc.calc_type == 'extra'")
                  .mt-3
                    span.text-muted.fw-500
                      | Груз:
                    div.pl-5
                      div(v-for="(item, index) in cargo", :key="index")
                        template(v-if="item.weight")
                          | {{item.weight}} кг,
                          |  {{item.length}}x{{item.width}}x{{item.height}} {{item.measure_LWH | dic(options.units)}}
                          sup
                            | 3
                          | ,
                          |  {{item.amount}} место
                template(v-if="isPackaging")
                  h4.my-3
                    | Упаковка
                  div.mt-2(v-if="calc.palletizing_service")
                    span.text-muted.fw-500
                      | Палетирование
                    span.ml-2
                      | {{response.palletizing_service_cost | abs | parts}} ₽
                  div.mt-2(v-if="calc.stretch_service")
                    span.text-muted.fw-500
                      | Стрейч пленка
                    span.ml-2
                      | {{response.stretch_service_cost | abs | parts}} ₽
                  div.mt-2(v-if="calc.bubble_service")
                    span.text-muted.fw-500
                      | Воздушно-пузырьковая пленка
                    span.ml-2
                      | {{response.bubble_service_cost | abs | parts}} ₽
                  div.mt-2(v-if="calc.pallet_board")
                    span.text-muted.fw-500
                      | Палетный борт
                    span.ml-2
                      | {{response.pallet_board_service_cost | abs | parts}} ₽
                  div.mt-2(v-if="calc.other_pack_service_check")
                    span.text-muted.fw-500
                      | Прочие услуги
                    span.ml-2
                      | {{response.other_service_cost | abs | parts}} ₽
                  div.mt-2(v-if="calc.rigid_packing_service")
                    span.text-muted.fw-500
                      | Жесткая упаковка
                    span.ml-2
                      | {{response.rigid_packings_service_cost | abs | parts}} ₽
                template(v-if="isOtherOptions")
                  h4.my-3
                    | Дополнительные услуги
                  div.mt-2(v-if="calc.insurance_service")
                    span.text-muted.fw-500
                      | Страхование
                    span.ml-2
                      | {{response.insurance_service_cost | abs | parts}} ₽
                  div.mt-2(v-if="calc.docs_service_check")
                    span.text-muted.fw-500
                      | Перевозка документов
                    span.ml-2
                      | {{response.docs_service_cost | abs | parts}} ₽
                  div.mt-2(v-if="calc.sender_loading")
                    span.text-muted.fw-500
                      | Погрузочные работы у отправителя
                    span.ml-2
                      | {{response.sender_service_loading_cost | abs | parts}} ₽
                  div.mt-2(v-if="calc.receiver_loading")
                    span.text-muted.fw-500
                      | Погрузочные работы у получателя
                    span.ml-2
                      | {{response.receiver_service_loading_cost | abs | parts}} ₽
                  div.mt-2(v-if="calc.thermo_service")
                    span.text-muted.fw-500
                      | Терморежим +5С - +25С
                    span.ml-2
                      | {{response.thermo_service_cost | abs | parts}} ₽
            c-col.col-12.block
              div(:class="{'d-none d-md-inline-block': !blocks.sender.show}")
                h3.mt-4(:class="{'text-muted': !blocks.sender.filled}")
                  | Отправитель
                  div.d-inline-block.text-primary.f-14.ml-3.hoverable(v-if="!blocks.sender.show && blocks.sender.filled", @click="editBlock('sender')")
                    i.icon-pencil.mr-2
                    span(v-if="!isBlockInvalid('sender')")
                      | Редактировать
                    span(v-if="isBlockInvalid('sender')")
                      | Продолжить заполнение
                div.mt-3.text-purple.fw-500(v-if="!blocks.sender.show && blocks.sender.filled && isBlockInvalid('sender')")
                  i.icon-warning.mr-2
                  | Не все данные заполнены
              div.mt-3.d-block.d-md-none(v-if='!blocks.sender.show && blocks.sender.filled', @click="editBlock('sender')")
                .w-100.btn.btn-outline-primary.py-2.px-4.text-left
                  c-row.flex-nowrap.align-items-center
                    c-col.f-14.fw-500
                      | Отправитель
                    c-col.col-auto
                      i.icon-angle-down.f-10
                div.py-2.bg-gray-200.text-gray-400.fa.justify-content-center(v-if="!blocks.calc.show && blocks.calc.filled && isBlockInvalid('sender')")
                  i.icon-warning.bg-gray-400.mr-2
                  | Не все данные заполнены
              div.mt-4(v-if='blocks.sender.show')
                c-row
                  c-col.col-12.col-md-6
                    template(v-if="options.senders.length > 1")
                      c-select.mt-3.mt-md-2(placeholder="Отправитель", :options= "options.senders", v-model="calc.sender_id")
                      template(v-if="calc.sender_id == 0")
                        c-subject.mt-2.mt-md-0(v-model='sender', :options='options')
                    template(v-else)
                      c-subject(v-model="sender", :options="options")
                .shadow-box.mt-4(v-if="!$viewer.logged")
                .mt-4.mb-4
                  c-button.w-100.w-md-auto(@click="check('sender')")
                    //- span(v-if="blocks.receiver.filled")
                    | Продолжить
                    //- span(v-else)
                      | Оформить заявку
                  c-button.d-none.d-md-inline-block.white.ml-3(v-if="!backlog", @click="backlog = true")
                    | Поручить оформление менеджеру
                  div.backlog.p-4.mt-4(v-if="backlog")
                    div.text-center.f-16
                      | Поручите оформление менеджеру.
                      div
                        | Укажите Ваш телефон, и мы вам обязательно перезвоним в течение 10 минут.
                    c-row.mt-3
                      c-col
                        c-input(v-model="calc.backlog_phone", subclass="large", placeholder="Телефон", v-mask="{mask: '+79999999999'}")
                      c-col.col-auto
                        c-button.backlog-button(@click="sendBacklog()", :disabled="$v.backlog.$invalid")
                          | Отправить
              div.mt-3.d-none.d-md-block(v-if='!blocks.sender.show && blocks.sender.filled')
                | {{sender.agent_name}}, {{sender.agent_phone}} 
                span(v-if="sender.agent_extra_phone")
                  | (доб. {{sender.agent_extra_phone}} )
                div.mt-1(v-if="sender.agent_type == 1")
                  | {{sender.agent_document_type_id | dic(options.docTypes)}} {{sender.agent_document_series}} {{sender.agent_document_number}}
                div(v-if="~[2,3].indexOf(sender.agent_type)")
                  div.mt-1
                    | ИНН {{sender.agent_inn}}, КПП {{sender.agent_kpp}}
                  div.mt-1
                    | {{sender.agent_contact}}
            c-col.col-12.block
              div(:class="{'d-none d-md-inline-block': !blocks.receiver.show}")
                h3.mt-4(:class="{'text-muted': !blocks.receiver.filled}")
                  | Получатель
                  div.d-inline-block.text-primary.f-14.ml-3.hoverable(v-if="!blocks.receiver.show && blocks.receiver.filled", @click="editBlock('receiver')")
                    i.icon-pencil.mr-2
                    span(v-if="!isBlockInvalid('receiver')")
                      | Редактировать
                    span(v-if="isBlockInvalid('receiver')")
                      | Продолжить заполнение
                div.mt-3.text-purple.fw-500(v-if="!blocks.receiver.show && blocks.receiver.filled && isBlockInvalid('receiver')")
                  i.icon-warning.mr-2
                  | Не все данные заполнены
              div.mt-3.d-block.d-md-none(v-if='!blocks.receiver.show && blocks.receiver.filled', @click="editBlock('receiver')")
                .w-100.btn.btn-outline-primary.py-2.px-4.text-left
                  c-row.flex-nowrap.align-items-center
                    c-col.f-14.fw-500
                      | Получатель
                    c-col.col-auto
                      i.icon-angle-down.f-10
                div.py-2.bg-gray-200.text-gray-400.fa.justify-content-center(v-if="!blocks.calc.show && blocks.calc.filled && isBlockInvalid('receiver')")
                  i.icon-warning.bg-gray-400.mr-2
                  | Не все данные заполнены
              div.mt-4(v-if='blocks.receiver.show')
                c-row
                  c-col.col-12.col-md-6
                    template(v-if="options.receivers.length > 1")
                      c-select.mt-3.mt-md-2(placeholder="Получатель", :options= "options.receivers", v-model="calc.recip_id")
                      template(v-if="calc.recip_id == 0")
                        c-subject.mt-2.mt-md-0(v-model='receiver', :options='options')
                    template(v-else)
                      c-subject(v-model="receiver", :options="options")
                    c-checkbox.shadow-box.d-md-inline-block.mt-3(v-model="customOrder", style="width:350px")
                      .fw-500.fw-md-normal
                        | Хочу указать свой номер заказа
                        c-tip.d-none.d-md-inline-block.tip-express.ml-2.align-middle
                          template(slot="subject")
                            i.icon-help.ml-1.f-16.lh-0.text-gray-300
                          div
                            | Вы можете присвоить свой идентификатор для данной заявки. Например, номер заявки вашего получателя
                    c-input.mt-2.mt-md-0(v-if="customOrder", v-model="order_id", placeholder="Номер заказа")
                .shadow-box.mt-4(v-if="$viewer.logged")
                  //- | Нажимая кнопку "отправить заявку" 
                  //- .d-inline.text-primary.hoverable(@click="openAgreement()")
                  //-   | подтверждаю согласие
                  //- |  на обработку персональных данных
                  c-checkbox(v-model="PDAgree")
                    .fw-500.fw-md-400
                      .d-inline-block.text-primary(@click="openAgreement()")
                        | Согласен(-на)
                      |  на обработку персональных данных
                .mt-4.mb-4
                  .alert.alert-danger.mb-0.f-12.d-block.my-4(v-if="$viewer.logged && orderError")
                    | {{orderError}}
                  c-button.w-100.w-md-auto(@click="check('receiver')", :disabled="sending")
                    //- ($viewer.logged ? !isFormValid : !isReceiverValid) || sending
                    template(v-if="!$viewer.logged")
                      | Продолжить
                    template(v-else)
                      | Отправить заявку
                      //- span(v-if="isFormValid")
                      //-   | Отправить заявку
                      //- span(v-else)
                      //-   | Чтобы продолжить, заполните данные
                  c-button.d-none.d-md-inline-block.white.ml-3(v-if="!backlog", @click="backlog = true")
                    | Поручить оформление менеджеру
                  div.backlog.p-4.mt-4(v-if="backlog")
                    div.text-center.f-16
                      | Поручите оформление менеджеру.
                      div
                        | Укажите Ваш телефон, и мы вам обязательно перезвоним в течение 10 минут.
                    c-row.mt-3
                      c-col
                        c-input(v-model="calc.backlog_phone", subclass="large", placeholder="Телефон", v-mask="{mask: '+79999999999'}")
                      c-col.col-auto
                        c-button.backlog-button(@click="sendBacklog()", :disabled="$v.backlog.$invalid")
                          | Отправить
              div.mt-3.d-none.d-md-block(v-if='!blocks.receiver.show && blocks.receiver.filled')
                | {{receiver.agent_name}}, {{receiver.agent_phone}}
                span(v-if="receiver.agent_extra_phone")
                  | (доб. {{receiver.agent_extra_phone}} )
                div.mt-1(v-if="receiver.agent_type == 1")
                  | {{receiver.agent_document_type_id | dic(options.docTypes)}} {{receiver.agent_document_series}} {{receiver.agent_document_number}}
                div(v-if="~[2,3].indexOf(receiver.agent_type)")
                  div.mt-1
                    | ИНН {{receiver.agent_inn}}, КПП {{receiver.agent_kpp}}
                  div.mt-1
                    | {{receiver.agent_contact}}
            c-col.col-12.block.payer
              div(:class="{'d-none d-md-inline-block': !blocks.payer.show}")
                h3.mt-4(:class="{'text-muted': !blocks.payer.filled}")
                  | Плательщик
                  div.d-inline-block.text-primary.f-14.ml-3.hoverable(v-if="!blocks.payer.show && blocks.payer.filled", @click="editBlock('payer')")
                    i.icon-pencil.mr-2
                    span(v-if="!isBlockInvalid('payer')")
                      | Редактировать
                    span(v-if="isBlockInvalid('payer')")
                      | Продолжить заполнение
                div.mt-3.text-purple.fw-500(v-if="!blocks.payer.show && blocks.payer.filled && isBlockInvalid('payer')")
                  i.icon-warning.mr-2
                  | Не все данные заполнены
              div.mt-3.d-block.d-md-none(v-if='!blocks.payer.show && blocks.payer.filled', @click="editBlock('payer')")
                .w-100.btn.btn-outline-primary.py-2.px-4.text-left
                  c-row.flex-nowrap.align-items-center
                    c-col.f-14.fw-500
                      | Плательщик
                    c-col.col-auto
                      i.icon-angle-down.f-10
                div.py-2.bg-gray-200.text-gray-400.fa.justify-content-center(v-if="!blocks.calc.show && blocks.calc.filled && isBlockInvalid('payer')")
                  i.icon-warning.bg-gray-400.mr-2
                  | Не все данные заполнены
              div.mt-4(v-if='blocks.payer.show')
                c-row
                  c-col.col-12.col-md-6
                    template(v-if="!$viewer.logged")
                      //- .shadow-box.mt-3.mt-md-2(:class="{active: payer_type == 'sender'}")
                      //-   c-radio(v-model='payer_type', type='sender')
                      //-     span.fw-500.fw-md-normal
                      //-       | Отправитель
                      //- .shadow-box.mt-3.mt-md-2(:class="{active: payer_type == 'receiver'}")
                      //-   c-radio(v-model='payer_type', type='receiver')
                      //-     span.fw-500.fw-md-normal
                      //-       | Получатель
                      //- .shadow-box.mt-3.mt-md-2(:class="{active: payer_type == 'third'}")
                      //-   c-radio(v-model='payer_type', type='third')
                      //-     span.fw-500.fw-md-normal
                      //-       | Третье лицо
                      c-select.mt-3.mt-md-2(placeholder='Плательщик', :options='options.payerTypes', v-model='payer_type')
                      c-select.mt-3.mt-md-2(placeholder='Форма оплаты', :options='options.paymentTypes', v-model='payer.type_pay' accessor="agents.$each[2].type_pay")
                      c-subject.mt-2.mt-md-0(v-if="payer_type == 'third'", v-model='payer', :options='options')
                    template(v-else)
                      c-select.mt-3.mt-md-2(placeholder="Выберите плательшика", :options= "options.payers", v-model="calc.payer_id", v-if="calc.payer_id === null")
                      c-select.mt-3.mt-md-2(placeholder="Плательшик", :options= "options.payers", v-model="calc.payer_id", v-else)
                      template(v-if="calc.payer_id == 0")
                        c-select(placeholder="Город", :options="options.cities", v-model="TownID", :filter="filterCities", :getOptionLabel="getCityLabel", accessor="TownID")
                        c-input.mt-2.mt-md-0(placeholder="Адрес", v-model="Address", accessor="Address")
                        c-select.mt-3.mt-md-2(placeholder='Форма оплаты', :options='options.paymentTypes', v-model='payer.type_pay' accessor="agents.$each[2].type_pay")
                        c-subject.mt-2.mt-md-0(v-model='payer', :options='options')
                .shadow-box.mt-4(v-if="!$viewer.logged")
                  //- | Нажимая кнопку "отправить заявку" 
                  //- .d-inline.text-primary.hoverable(@click="openAgreement()")
                  //-   | подтверждаю согласие
                  //- |  на обработку персональных данных
                  c-checkbox(v-model="PDAgree")
                    .fw-500.fw-md-400
                      .d-inline-block.text-primary(@click="openAgreement()")
                        | Согласен(-на)
                      |  на обработку персональных данных
                .mt-4.mb-4
                  .alert.alert-danger.mb-0.f-12.d-block.my-4(v-if="!$viewer.logged && orderError")
                    | {{orderError}}
                  c-button.w-100.w-md-auto(@click="check('payer')", :disabled="sending")
                    //- ($viewer.logged ? !isPayerValid : !isFormValid) || sending
                    template(v-if="$viewer.logged")
                      | Продолжить
                    template(v-else)
                      | Отправить заявку
                      //- span(v-if="isFormValid")
                      //-   | Отправить заявку
                      //- span(v-else)
                      //-   | Чтобы продолжить, заполните данные
                  c-button.d-none.d-md-inline-block.white.ml-3(v-if="!backlog", @click="backlog = true")
                    | Поручить оформление менеджеру
                  div.backlog.p-4.mt-4(v-if="backlog")
                    div.text-center.f-16
                      | Поручите оформление менеджеру.
                      div
                        | Укажите Ваш телефон, и мы вам обязательно перезвоним в течение 10 минут.
                    c-row.mt-3
                      c-col
                        c-input(v-model="calc.backlog_phone", subclass="large", placeholder="Телефон", v-mask="{mask: '+79999999999'}")
                      c-col.col-auto
                        c-button.backlog-button(@click="sendBacklog()", :disabled="$v.backlog.$invalid")
                          | Отправить
              div.mt-4.d-none.d-md-block(v-if='!blocks.payer.show && blocks.payer.filled')
                | {{payer.agent_name}}, {{payer.agent_phone}} 
                span(v-if="payer.agent_extra_phone")
                  | (доб. {{payer.agent_extra_phone}} )
                div.mt-1(v-if="payer.agent_type == 1")
                  | {{payer.agent_document_type_id | dic(options.docTypes)}} {{payer.agent_document_series}} {{payer.agent_document_number}}
                div(v-if="~[2,3].indexOf(payer.agent_type)")
                  div.mt-1
                    | ИНН {{payer.agent_inn}}, КПП {{payer.agent_kpp}}
                  div.mt-1
                    | {{payer.agent_contact}}
        c-col.col-12.col-md-4
          .total-cont(:class="{show: showBill}")
            .spacer(@click="closeBar")
            .total-button.d-block.d-md-none(:class="{show: !showBill}", @click="openBar")
              c-row.flex-nowrap.alig-items-center.bg-primary.p-3
                c-col.fw-bold.f-10.text-uppercase
                  .text-white
                    | Общая стоимость
                  .click-to-order
                    | Нажмите чтобы заказать
                c-col.col-auto.text-white.fw-bold.text-right
                  .h-100.fa.f-14
                    div
                      span.f-12
                        | ₽
                      span
                        |  {{response.total_cost | abs | parts}}
            .d-block.d-md-none.grads
              .top
              .bottom
            .info-cont.pt-md-4(:class="{show: showBill}")
              preloader(over, v-if="sending")
              .info-head.mx-0.f-0
                .center
                .area-close(@click="closeBar")
              .info-data.px-4.py-3(@scroll="scrollInfoData")
                .info(v-if="response.is_calc_standart")
                  c-row.flex-nowrap
                    c-col
                      .f-16.fw-500.text-gray-300(v-if="!errors")
                        | Предварительный расчет
                    c-col.col-auto(v-if="saved")
                      c-tip(direction="left")
                        template(slot="subject")
                          i.icon-star.bg-primary
                        | Сохраненный расчет {{calc.create_date | date}}
                  .total.text-primary.mt-3.pt-1(v-if="response.total_cost && !errors")
                    | ₽ {{response.total_cost | abs | parts}}
                  .total.mt-3.pt-1.mb-4.f-14.lh-1-4(v-else-if="errors")
                    i.icon-warning2.calc-warning.mb-4
                    div.calc-warning-text
                      | Для расчета стоимости заполните, пожалуйста, все обязательные поля
                  template(v-if="!errors")
                    .path.mt-3.py-1(v-if="calc.city_from_id && calc.city_to_id")
                      c-row
                        c-col.pr-0.left(cols="auto")
                          .text-center
                            .red-circle
                            div
                              .path-line
                            .red-circle
                        c-col.pl-2.right
                          .city
                            | {{calc.city_from_id | dic(options.citiesAll)}}
                          .dates
                            | {{calc.cargo_pickup_date | date}} - {{calc.cargo_delivery_date | date}}
                          .city
                            | {{calc.city_to_id | dic(options.citiesAll)}}
                    .inverted.dimensions.mx-n4.px-4.py-3.mt-3.fa.fw-500
                      i.icon-cargo.text-gray-300(v-if="response.cargo_weight")
                      span.ml-2(v-if="response.cargo_weight")
                        |  {{response.cargo_weight}} кг
                      i.icon-fullscreen.text-gray-300.ml-3(v-if="response.cargo_volume")
                      span.ml-2(v-if="response.cargo_volume")
                        | {{response.cargo_volume}} м
                        sup
                          | 3
                    .mt-3
                      c-row.my-2(v-if="response.pickup_cost > 0")
                        c-col.text-gray-300.fw-500
                          | Забрать груз
                        c-col.text-primary.fw-500(cols="auto")
                          | ₽ {{response.pickup_cost | abs | parts}}
                      c-row.my-2(v-if="response.transport_cost > 0")
                        c-col.text-gray-300.fw-500
                          | Перевозка
                        c-col.text-primary.fw-500(cols="auto")
                          | ₽ {{response.transport_cost | abs | parts}}
                      c-row.my-2(v-if="response.cargo_processing_cost > 0")
                        c-col.text-gray-300.fw-500
                          | Обработка груза
                        c-col.text-primary.fw-500(cols="auto")
                          | ₽ {{response.cargo_processing_cost | abs | parts}}
                      c-row.my-2(v-if="response.delivery_cost > 0")
                        c-col.text-gray-300.fw-500
                          | Доставка
                        c-col.text-primary.fw-500(cols="auto")
                          | ₽ {{response.delivery_cost | abs | parts}}
                      c-row.my-2(v-if="calcServicesCost > 0")
                        c-col.text-gray-300.fw-500
                          | Доп. услуги
                        c-col.text-primary.fw-500(cols="auto")
                          //- | ₽ {{response.services_cost | abs | parts}}
                          | ₽ {{calcServicesCost | abs | parts}}
                      c-row.my-2(v-if="Math.abs(response.insurance_service_cost) > 0")
                        c-col.text-gray-300.fw-500
                          | Страхование груза
                        c-col.text-primary.fw-500(cols="auto")
                          | ₽ {{response.insurance_service_cost | abs | parts}}
                      c-row.my-2(v-if="response.packing_cost > 0")
                        c-col.text-gray-300.fw-500
                          | Упаковка
                        c-col.text-primary.fw-500(cols="auto")
                          | ₽ {{response.packing_cost | abs | parts}}
                      c-row.my-2(v-if="response.thermo_cost >= 0")
                        c-col.text-gray-300.fw-500
                          | Терморежим +5С - +25С
                        c-col.fw-500(cols="auto", :class="{'text-primary': response.thermo_cost > 0, 'text-gray-300': response.thermo_cost <= 0}")
                          | ₽ {{response.thermo_cost | abs | parts}}
                  .d-none.d-md-block
                    c-button.white.w-100.mt-3(@click="getSpec")
                      | Получить спецпредложение
                    c-button.white.w-100.mt-3(@click="getPrintForm()", v-if="blocks.calc.filled && !isBlockInvalid('calc')")
                      | Скачать расчет
                  .d-block.d-md-none
                    c-button.w-100.mt-3(@click="check('payer')", :disabled="!isFormValid || sending")
                      span(v-if="!isFormValid")
                        | Чтобы продолжить, заполните данные
                      span(v-else)
                        | Отправить заявку
                    c-button.w-100.white.mt-3(v-if="!backlog", @click="backlog = true")
                      | Поручить оформление менеджеру
                    div(v-if="backlog")
                      .mt-3
                        c-input(v-model="calc.backlog_phone", subclass="large", placeholder="Телефон", v-mask="{mask: '+79999999999'}")
                      c-button.w-100.mt-3.backlog-button(@click="sendBacklog()", :disabled="$v.backlog.$invalid")
                        | Отправить
                  template(v-if="$viewer.logged")
                    //- .d-none.d-md-block
                    c-button.w-100.mt-3(@click="saveRequest()")
                      | Сохранить расчет
                    button.btn.btn-link.f-14.fw-500.w-100.mt-3(@click="openSavedRequests()")
                      | К списку сохраненных расчетов
                .info(v-if="!response.is_calc_standart")
                  c-row
                    c-col
                      div.text-center
                        i.icon-support.text-gray-300
                      h4.text-center.mt-3
                        | Нестандартная перевозка
                      div.text-center.text-muted.mt-3.f-14
                        | По указанным данным требуется индивидуальный расчёт. Пожалуйста, свяжитесь с менеджером для расчета стоимости доставки.
                      c-input.mt-3(v-model="calc.backlog_phone", subclass="large", placeholder="Оставьте ваш телефон здесь", v-mask="{mask: '+79999999999'}")
                      c-button.w-100.mt-3(@click="sendBacklog()")
                        | Отправить запрос
                      c-button.white.w-100.mt-3(@click="getSpec")
                        | Получить спецпредложение
      preloader.py-5.my-5(v-else)
      .modal.fade(tabindex='-1', role='dialog', data-backdrop="false", data-keyboard="false", :class="{show: modals.badConnection}", v-if="modals.badConnection")
        .modal-dialog-centered.modal-bad-connection(role='document')
          .modal-content.connectionerr-container.mx-auto
            .modal-body.connectionerr-box
              div.connectionerr-title.mb-2.mt-4
                | Нет соединения
              div.connectionerr-text.mb-4
                | Пожалуйста, повторите запрос
              i.icon-connection-error.connectionerr-icon.mb-4
              button.btn.btn-primary.mt-3.mb-4(type='button', @click="modals.badConnection = false; calculate()") Повторить запрос
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import {required, requiredIf, minLength, minValue, email, helpers, numeric} from 'vuelidate/lib/validators';
import validateObject from './../core/validation-to-object';
import api from './../api';
import {agent, agentDebug, CARGO, DEMO, initialData, necessaryCalcFieldsList} from "@/js/calculator/fixture";
import {
  focusHandler,
  isOther,
  isEqual,
  notEmpty,
  phoneCheck,
  skip,
  isDate,
  keep,
  isTimeRange,
  swapProperties,
} from "@/js/calculator/helpers";

const AGENT = DEBUG ? agentDebug : agent

let needResetDate = false;


let agentsWatchHandler = function () {
  this.processPayer(this.payer_type);
};

export default {
  data: () => initialData,
  computed: {
    sender() {
      return this.getAgent(1);
    },
    receiver() {
      return this.getAgent(2);
    },
    payer() {
      return this.getAgent(3);
    },
    isPackaging() {
      return _.reduce([
        'palletizing_service',
        'stretch_service',
        'bubble_service',
        'pallet_board',
        'rigid_packing_service',
        'other_pack_service_check'
      ], (s, key) => s || this.calc[key], false);
    },
    isOtherOptions() {
      return _.reduce([
        'insurance_service',
        'docs_service_check',
        'thermo_service',
        'sender_loading',
        'receiver_loading'
      ], (s, key) => s || this.calc[key], false);
    },
    isCalcValid() {
      return !(this.$v.calc.$invalid || this.$v.cargo.$invalid);
    },
    isSenderValid() {
      let index = _.findIndex(this.agents, agent => agent.agent_role == 1);
      return !this.$v.agents.$each[index].$invalid;
    },
    isReceiverValid() {
      let index = _.findIndex(this.agents, agent => agent.agent_role == 2);
      let other = this.$viewer.logged ? this.PDAgree : true;
      return !this.$v.agents.$each[index].$invalid && other;
    },
    isPayerValid() {
      let index = _.findIndex(this.agents, agent => agent.agent_role == 3);
      let other;
      if (this.$viewer.logged) {
        other = true;
        if (~['sender', 'receiver'].indexOf(this.payer_type)) {
          return !!this.payer.type_pay;
        }
      } else {
        other = this.PDAgree;
      }
      return !this.$v.agents.$each[index].$invalid && other;
    },
    isFormValid() {
      return this.isCalcValid && this.isSenderValid && this.isReceiverValid && this.isPayerValid && !this.$v.PDAgree.$invalid;
    },
    isFormFilled() {
      return this.blocks.calc.filled && this.blocks.sender.filled && this.blocks.receiver.filled && this.blocks.payer.filled;
    },
    calcServicesCost() {
      // return this.response.services_cost - Math.abs(this.response.insurance_service_cost)
      return this.response.services_cost
    }
  },
  provide() {
    return {
      validation: this.$v
    }
  },
  validations() {
    let skipAgentValidation = validators => (value, model) => {
      if ((model.agent_role == 1 && this.calc.sender_id > 0) ||
          (model.agent_role == 2 && this.calc.recip_id > 0) ||
          (model.agent_role == 3 && this.calc.payer_id > 0)
      ) {
        // console.log('skipped');
        return true;
      }
      let result = false;
      if (_.isFunction(validators)) {
        validators = [validators];
      }
      if (_.isPlainObject(validators) || _.isArray(validators)) {
        result = _.reduce(validators, (s, validator) => s && validator.call(this, value, model), true);
      }
      // console.log('validating', value, result);
      return result;
    }
    return {
      calc: {
        city_from_id: {required},
        cargo_pickup: {required},
        cargo_pickup_date: {required, date: isDate()},
        cargo_pickup_time: {keep, isTimeRange},
        pickup_comment: {keep},
        cargo_pickup_id: {skip: skip((value, model) => !model.cargo_pickup, [required, numeric])},
        cargo_pickup_adress: {skip: skip((value, model) => model.cargo_pickup, [required, notEmpty])},

        city_to_id: {required},
        cargo_delivery: {required},
        cargo_delivery_date: {required, date: isDate()},
        cargo_delivery_time: {keep, isTimeRange},
        delivery_comment: {keep},
        cargo_delivery_id: {skip: skip((value, model) => !model.cargo_delivery, [required, numeric])},
        cargo_delivery_adress: {skip: skip((value, model) => model.cargo_delivery, [required, notEmpty])},

        express_service: {keep},

        calc_type: {required},

        ...(this.calc.calc_type == 'common' ? {
          common_cargo_weight: {required, minValue: minValue(0.0001)},
          common_cargo_volume: {required, minValue: minValue(0.0001)},
          common_cargo_amount: {required, minValue: minValue(0.0001)},
          is_overall: {required},
          maxlength: {required, minValue: minValue(0.0001)},
          maxwidth: {required, minValue: minValue(0.0001)},
          maxheight: {required, minValue: minValue(0.0001)},
          maxweight: {required, minValue: minValue(0.0001)}
        } : {}),

        cargo_kind_id: {required},
        cargo_name: {required},

        // упаковка
        palletizing_service: {required},
        stretch_service: {required},
        bubble_service: {required},
        pallet_board: {required},
        rigid_packing_service: {required},
        other_pack_service_check: {required},
        other_pack_service: {skip: skip((value, model) => model.other_pack_service_check, [required, notEmpty])},

        // доп услуги
        insurance_service: {required},
        cargo_cost: this.calc.insurance_service ? {required, min: minValue(0.1)} : {},

        docs_service_check: {required},
        docs_service: this.calc.docs_service_check ? {required} : {},

        sender_loading: {required},
        ...(this.calc.sender_loading ? {
          // sender_loading_weight: {required},
          sender_loading_floor: {required}
        } : {}),

        receiver_loading: {required},
        ...(this.calc.receiver_loading ? {
          // receiver_loading_weight: {required},
          receiver_loading_floor: {required}
        } : {}),

        thermo_service: {required},

        backlog_phone: {keep},
        order_type: {keep},

        payer_id: {keep},
        sender_id: {keep},
        recip_id: {keep},
        creator_id: {keep}
      },
      cargo: this.calc.calc_type == 'extra' ? {
        $each: {
          weight: {required, minValue: minValue(0.0001)},
          width: {required, minValue: minValue(0.0001)},
          height: {required, minValue: minValue(0.0001)},
          length: {required, minValue: minValue(0.0001)},
          measure_LWH: {required},
          amount: {required, minValue: minValue(1)}
        }
      } : {},
      agents: {
        $each: {
        //   agent_type: {required},
        //   agent_name: {required},
        //   agent_document_type_id: {skip: skip((value, model) => model.agent_type == 1, required)},
        //   agent_document_series: {skip: skip((value, model) => model.agent_type == 1, required)},
        //   agent_document_number: {skip: skip((value, model) => model.agent_type == 1, required)},
        //   agent_inn: {keep}, // {skip: skip((value, model) => ~[2,3].indexOf(model.agent_type), required)},
        //   agent_kpp: {keep}, // {skip: skip((value, model) => ~[2,3].indexOf(model.agent_type), required)},
        //   agent_contact: {skip: skip((value, model) => ~[2, 3].indexOf(model.agent_type), required)},
        //   agent_phone: {phoneCheck},
        //   agent_extra_phone: {keep},
        //   agent_email: {required, email},
        //   agent_is_informing_sms: {keep},
        //   agent_is_informing_mail: {keep},
        //   type_pay: {required, notEmpty},
        // }
        // {
          agent_role: {required},
          agent_type: {skip: skipAgentValidation(required)},
          agent_name: {skip: skipAgentValidation(required)},
          agent_document_type_id: {skip: skipAgentValidation(keep)}, // {skip: skip((value, model) => model.agent_type == 1, required)},
          agent_document_series: {skip: skipAgentValidation(keep)}, // {skip: skip((value, model) => model.agent_type == 1, required)},
          agent_document_number: {skip: skipAgentValidation(keep)}, // {skip: skip((value, model) => model.agent_type == 1, required)},
          agent_inn: {skip: skipAgentValidation(keep)}, // {skip: skip((value, model) => ~[2,3].indexOf(model.agent_type), required)},
          agent_kpp: {skip: skipAgentValidation(keep)}, // {skip: skip((value, model) => ~[2,3].indexOf(model.agent_type), required)},
          agent_contact: {skip: skipAgentValidation(skip((value, model) => ~[2, 3].indexOf(model.agent_type), required, 'azaza'))},
          agent_phone: {skip: skipAgentValidation(required) /*phoneCheck*/},
          agent_extra_phone: {keep},
          agent_email: {skip: skipAgentValidation(value => value ? email(value) : true)},
          agent_is_informing_sms: {keep},
          agent_is_informing_mail: {keep},
          type_pay: {skip: skipAgentValidation(skip((value, model) => model.agent_role == 3, [required, notEmpty]))}
        }
      },
      TownID: {required},
      Address: {required},
      backlog: {
        check: function () {
          return phoneCheck(this.calc.backlog_phone);
        }
      },
      payer_type: {required},
      PDAgree: {
        check: function () {
          return notEmpty(this.PDAgree);
        }
      }
    };
  },
  watch: {
    customOrder(val) {
      if (!val && this.order_id) this.order_id = null
    },
    payer_type(type) {
      this.processPayer(type);
    },
    calc: {
      handler: focusHandler,
      deep: true
    },
    'calc.cargo_pickup': function (val) {
      if (val != 1) {
        this.calc.sender_loading = false
        this.calc.cargo_pickup_date = new Date()
        let day = new Date(this.calc.cargo_pickup_date).getDay()
        if (day > 5) this.calc.cargo_pickup_date.setDate(this.calc.cargo_pickup_date.getDate() + (8 - day))
      }
    },
    'calc.cargo_delivery': function (val) {
      if (val != 1) this.calc.receiver_loading = false
    },
    'calc.city_from_id': function (value, newValue) {
      // console.log(value)
      let town = _.find(this.options.towns, town => town.town_id == value);
      if (town) {
        this.calc.city_from = _.get(town, 'area_name') || this.calc.city_from;
      }
      if (town && !town.is_filial) {
        this.calc.cargo_pickup = true;
      }
      if (newValue && value && value != newValue) {
        needResetDate = true;
      }
      this.loadAgentsDebounced();
    },
    'calc.city_to_id': function (value, newValue) {
      // console.log(value)
      let town = _.find(this.options.towns, town => town.town_id == value);
      if (town) {
        this.calc.city_to = _.get(town, 'area_name') || this.calc.city_to;
      }
      if (town && !town.is_filial) {
        this.calc.cargo_delivery = true;
      }
      if (newValue && value && value != newValue) {
        needResetDate = true;
      }
      this.loadAgentsDebounced();
    },
    'calc.sender_id': function (value) {
      let sender = _.find(this.options.senders, sender => sender.value == value);
      if (value && sender) {
        let exclude = ['agent_role'];
        let ancestorFields = _.omit(sender.item, exclude);
        _.extend(this.sender, ancestorFields);
      }
    },
    'calc.recip_id': function (value) {
      let receiver = _.find(this.options.receivers, receiver => receiver.value == value);
      if (value && receiver) {
        let exclude = ['agent_role'];
        let ancestorFields = _.omit(receiver.item, exclude);
        _.extend(this.receiver, ancestorFields);
      }
    },
    'calc.payer_id': function (value) {
      let payer = _.find(this.options.payers, payer => payer.value == value);
      if (value && payer) {
        let exclude = ['agent_role'];
        let ancestorFields = _.omit(payer.item, exclude);
        _.extend(this.payer, ancestorFields);
      } else {
        let exclude = ['agent_role'];
        let ancestorFields = _.omit(payer?.item, exclude);
        _.extend(this.payer, ancestorFields);
      }
    },
    cargo: {
      handler: focusHandler,
      deep: true
    },
    sender: {
      handler: agentsWatchHandler,
      deep: true
    },
    receiver: {
      handler: agentsWatchHandler,
      deep: true
    },
    payer: {
      handler() {
        this.debouncedSaveCalc();
      },
      deep: true
    }
  },
  methods: {
    getPrintForm() {
      let vObject = validateObject(this, this.$v);
      // console.log(vObject.calc.cargo_kind_id);
      let request = _.merge({}, _.omit(this.defaults, ['calc_response']), {
        calc_fields: _.extend(_.cloneDeep(vObject.calc), {
          city_from: _.get(_.find(this.options.towns, town => town.town_id == this.calc.city_from_id), 'town_name') || '',
          city_to: _.get(_.find(this.options.towns, town => town.town_id == this.calc.city_to_id), 'town_name') || '',
          cargo_pickup_date: moment(this.calc.cargo_pickup_date).format('YYYY-MM-DD'),
          cargo_delivery_date: moment(this.calc.cargo_delivery_date).format('YYYY-MM-DD'),
          cargo_kind_id: vObject.calc.cargo_kind_id == -100 ? 0 : vObject.calc.cargo_kind_id
        }),
        cargo_fields: _.cloneDeep(vObject.cargo) || [],
        agents: _.cloneDeep(vObject.agents) || [],
        calc_response: _.omit(this.response, ['TB response', 'TB request'])
      });
      api.getCalcPrintForm(request.calc_fields, request.cargo_fields, request.calc_response, request.agents).then(res => {
        let blob = new Blob([res.data], {type: 'application/pdf'})
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        const regex = /filename\*?=((['"])[\s\S]*?\2|[^;\n]*)/
        const filename = regex.exec(res.headers["content-disposition"])
        let name = 'Скиф-Карго_расчет_стоимости_доставки.pdf'
        try {
          name = decodeURI(filename[1])
        } catch (e) {
          return 'file'
        }
        link.download = name
        link.click()
      })
    },
    processPayer(type) {
      let payer = _.cloneDeep(this.payer);
      let exclude = ['type_pay', 'agent_role'];
      // console.log(this.payer_type)
      // console.log(type, this.calc.payer_id)
      if (this.calc.payer_id <= 0 && type) {
        for (let key in payer) {
          if (payer.hasOwnProperty(key) && !~exclude.indexOf(key)) {
            payer[key] = null;
          }
        }

        let ancestor;
        if (type == 'third' && this.calc.payer_id != 0) {
          this.calc.payer_id = 0;
        }
        if (type == 'sender') {
          ancestor = _.cloneDeep(this.sender);
          if (this.calc.payer_id != -1) {
            this.calc.payer_id = -1;
          }
        }
        if (type == 'receiver') {
          ancestor = _.cloneDeep(this.receiver);
          if (this.calc.payer_id != -2) {
            this.calc.payer_id = -2;
          }
        }

        let payerFields = _.omit(payer, exclude);
        let ancestorFields = _.omit(ancestor, exclude);
        if (ancestor && !_.isEqual(payerFields, ancestorFields)) {
          // console.log('payer changed');
          // ancestor.agent_role = 3;
          _.extend(this.payer, ancestorFields);
        } else {
          // console.log('payer not changed');
        }
      }
      this.debouncedSaveCalc();
    },
    getTownInfo(id) {
      return _.get(this.options.towns, `[${id}]`) || {};
    },
    editBlock(type) {
      let agentIndex;
      switch (type) {
        case 'calc':
          this.$v.calc.$touch();
          this.$v.cargo.$touch();
          break;
        case 'sender':
          agentIndex = _.findIndex(this.agents, agent => agent.agent_role == 1);
          break;
        case 'receiver':
          agentIndex = _.findIndex(this.agents, agent => agent.agent_role == 2);
          break;
        case 'payer':
          agentIndex = _.findIndex(this.agents, agent => agent.agent_role == 3);
          break;
      }
      if (agentIndex) {
        let agent = this.$v.agents.$each[agentIndex];
        if (agent) agent.$touch();
      }
      _.each(this.blocks, obj => {
        obj.show = false;
      });
      this.blocks[type].show = true;
    },
    isBlockInvalid(type) {
      let index;
      let checkReceiver = this.$viewer.logged && type == 'receiver' ? !this.PDAgree : false;
      let checkPayer = !this.$viewer.logged && type == 'payer' ? !this.PDAgree : false;
      switch (type) {
        case 'calc':
          return this.$v.calc.$invalid || this.$v.cargo.$invalid;
        case 'sender':
          index = _.findIndex(this.agents, agent => agent.agent_role == 1);
          return _.get(this.$v.agents, `$each[${index}].$invalid`);
        case 'receiver':
          index = _.findIndex(this.agents, agent => agent.agent_role == 2);
          return _.get(this.$v.agents, `$each[${index}].$invalid`) || checkReceiver;
        case 'payer':
          index = _.findIndex(this.agents, agent => agent.agent_role == 3);
          return _.get(this.$v.agents, `$each[${index}].$invalid`) || checkPayer;

      }
    },
    getAgent(role) {
      let agent = _.find(this.agents, agent => agent.agent_role == role);
      if (!agent) {
        agent = _.cloneDeep(AGENT);
        agent.agent_role = role;
        this.agents = _.concat(this.agents, [agent]);
      }
      return agent;
    },
    swapLocation() {
      swapProperties(this.calc, 'city_from_id', 'city_to_id');
      swapProperties(this.calc, 'cargo_pickup', 'cargo_delivery');
      swapProperties(this.calc, 'cargo_pickup_adress', 'cargo_delivery_adress');
    },
    addCargo() {
      this.cargo.push(_.cloneDeep(CARGO));
    },
    removeCargo(index) {
      this.cargo = this.cargo.filter((item, findex) => findex != index);
      this.defaults.cargo_fields = this.defaults.cargo_fields.filter((item, findex) => findex != index);
    },
    nextStep(prev, next) {
      this.blocks[prev].show = false;
      if (next) {
        this.blocks[next].show = true;
        this.blocks[next].filled = true;
      }
      this.$nextTick(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      });
    },
    prepareCalcRequest() {
      let vObject = validateObject(this, this.$v);
      let request = _.merge({}, _.omit(this.defaults, ['calc_response', 'agent_fields']), {
        calc_fields: _.extend(_.cloneDeep(vObject.calc), {
          city_from: _.get(_.find(this.options.towns, town => town.town_id == this.calc.city_from_id), 'town_name') || '',
          city_to: _.get(_.find(this.options.towns, town => town.town_id == this.calc.city_to_id), 'town_name') || '',
          cargo_pickup_date: moment(this.calc.cargo_pickup_date).format('YYYY-MM-DD'),
          cargo_delivery_date: moment(this.calc.cargo_delivery_date).format('YYYY-MM-DD'),
          cargo_kind_id: vObject.calc.cargo_kind_id == -100 ? 0 : vObject.calc.cargo_kind_id
        }),
        cargo_fields: _.cloneDeep(vObject.cargo) || [],
        agents: _.cloneDeep(vObject.agents)
      });
      if (needResetDate) {
        request.calc_fields.cargo_pickup_date = moment().format('YYYY-MM-DD');
      }
      return request;
    },
    calculate() {
      if (!this.sending) {
        this.sending = true;
        // this.errors = false;
        let request = this.prepareCalcRequest();
        // console.log('[REQUEST]', request);
        return api.calculate(request).then(response => {
          let data = response.data;
          // console.log('[RESPONSE]', data);
          if (_.isPlainObject(data) && !data.errors) {
            this.errors = false;
            _.extend(this.response, data);
            this.parseResponse();
          } else if (data?.errors) {
            this.errors = true;
            // if (data.errors.cargo_fields) {
            //   for (let i=0; i < data.errors.cargo_fields.length; i++){
            //     if (data.errors.cargo_fields[i].err_message) {
            //       this.errors.push(data.errors.cargo_fields[i].field_name + ' ' + data.errors.cargo_fields[i].err_message)
            //     }
            //   }
            // }
            // if (data.errors.calc_fields) {
            //   for (let i=0; i < data.errors.calc_fields.length; i++){
            //     if (data.errors.calc_fields[i].err_message) {
            //       this.errors.push(data.errors.calc_fields[i].field_name + ' ' + data.errors.calc_fields[i].err_message)
            //     }
            //   }
            // }
            // console.log(this.errors)
          }
        }, error => {
          // console.log(error.response)
          // console.log(error.request)
          let request = _.get(error, 'request')
          let isOnline = _.get(window, 'navigator.onLine')
          if (!isOnline || (request && request.status == 0 && request.statusText == '')) {
            this.modals.badConnection = true
          }
        }).finally(() => {
          this.sending = false;
        });
      }
    },
    saveCalcRequest() {
      if (this.debouncedCalculate) {
        let request = this.prepareCalcRequest();
        this.$store.commit('saveCalc', request);
      }
    },
    getCityLabel(option) {
      return _.get(this.options.towns[option], 'town_name');
    },
    filterTime(options, search) {
      return [];
    },
    filterCities(options, search) {
      return search ? _.filter(this.options.citiesAll, town => (town.title || '').toLowerCase().indexOf(search.toLowerCase()) == 0) : options;
    },
    filterCargoType(options, search) {
      return search ? _.filter(options, option => (option.title || '').toLowerCase().indexOf(search.toLowerCase()) == 0) : options;
    },
    parseCity(city) {
      return city ? {
        value: city.town_id,
        title: city.town_name,
        subtitle: city.area_name
      } : city;
    },
    scrollToInvalid() {
      setTimeout(() => {
        let invalid = document.querySelector('.labeled.is-invalid');
        if (invalid) {
          let top = invalid.getBoundingClientRect().top;
          window.scrollBy({top: top - 100, left: 0, behavior: 'smooth'});
        }
      });
    },
    getFirstInvalidBlock() {
      if (this.$viewer.logged && this.isBlockInvalid('payer')) {
        return 'payer';
      }
      if (this.isBlockInvalid('calc')) {
        return 'calc';
      }
      if (this.isBlockInvalid('sender')) {
        return 'sender';
      }
      if (this.isBlockInvalid('receiver')) {
        return 'receiver';
      }
      if (this.isBlockInvalid('payer')) {
        return 'payer';
      }
    },
    check(type) {
      let index;
      let agentV;
      let invalidBlockName = this.getFirstInvalidBlock();
      // console.log('invalid', invalidBlockName)
      // console.log(this.$v.agents.$each)
      this.orderError = null;
      if ((this.$viewer.logged && type != 'receiver') || (!this.$viewer.logged && type != 'payer')) {
        switch (type) {
          case 'calc':
            this.nextStep('calc', 'sender');
            return;
          case 'sender':
            this.nextStep('sender', 'receiver');
            return;
          case 'receiver':
            this.nextStep('receiver', 'payer');
            return;
          case 'payer':
            // Новый плательщик
            if (this.calc.payer_id === 0) {
              // Подготавливаем данные пустые строки меняем на null
              // не стал менять в дефолтных данных, т.к. боюсь сайд эффектов
              const data = {...this.payer}
              Object.keys(data).forEach(key => data[key] = data[key] === '' ? null : data[key]);

              // удаляем id и role (эндпоин валится)
              delete data.agent_id
              delete data.agent_role

              api.createPayer({
                TownID: this.TownID,
                Address: this.Address,
                AddEmail: "",
                AddPhone: null,
                ...data
              })
                  .then(({data}) => {
                    this.$notify({
                      group: 'foo',
                      clean: true
                    })
                    if (data.status == 200 && !data.error) {
                      this.payer.agent_id = data.payer_id
                      this.options.payers.push({
                        title: this.payer.agent_name,
                        value: this.payer.agent_id,
                        item: this.payer
                      })
                      this.calc.payer_id = data.payer_id
                    }
                    if (data.error) {
                      this.error = data.error;
                    }
                    this.nextStep('payer', 'calc');
                  })
            } else {
              this.nextStep('payer', 'calc');
              return;
            }
        }
      }
      if (this.isFormValid && this.isFormFilled) {
        this.createOrder();
      } else {
        this.nextStep(type, invalidBlockName);
      }
    },
    openAgreement() {
      window.open('/media/documents/%D0%A3%D1%81%D0%BB%D0%BE%D0%B2%D0%B8%D1%8F_%D1%81%D0%B5%D1%80%D0%B2%D0%B8%D1%81%D0%B0_%D0%A1%D0%BE%D0%B3%D0%BB%D0%B0%D1%81%D0%B8%D0%B5_%D0%BD%D0%B0_%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D1%83_%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85_%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85_skif-cargo.ru.pdf', '_blank');
    },
    showPrr() {
      window.open('/media/documents/%D0%BF%D1%80%D0%B0%D0%B9%D1%81_%D0%9F%D0%A0%D0%A0_%D1%81_01.02.2023.xlsx', '_blank')
    },
    sendBacklog() {
      this.calc.order_type = 0;
      this.createOrder();
    },
    load() {
      return api.defaultCalc().then(async (response) => {
        let def = _.cloneDeep(response.data);
        let data = _.cloneDeep(DEMO);
        let copyData
        let copySetting

        if (localStorage.copyObject) {
          copySetting = JSON.parse(localStorage.getItem('copyObject'))
          localStorage.removeItem('copyObject')

          copyData = await api.getOrderRequestBody(copySetting.order.OrderNo)
        }

        if(copyData?.data ){
          const { data } = copyData
          Object.keys(data).forEach(key => {
            data[key] = data[key] === '' ? null : data[key]
            //if (key.includes('receiver') ||  key.includes('sender') || key.includes('payer')) {
           //   delete data[key]
            //}
          })
          // if (!copySetting.copyConf.direction){
          //   data.city_from = ""
          //   data.city_from_id = null
          //   data.city_to = ""
          //   data.city_to_id = null
          // }
          // if (!copySetting.copyConf.cargoParameters){
          //   data.common_cargo_weight = 0
          //   data.common_cargo_volume = 0
          //   data.common_cargo_amount = 0
          //   data.cargo_name = 0
          //   data.cargo_cost = 0
          // }
          // if (!copySetting.copyConf.services){
          //   data.palletizing_service = 0
          //   data.stretch_service = 0
          //   data.bubble_service = 0
          //   data.pallet_board = 0
          //   data.rigid_packing_service = 0
          // }
          this.temp.payer_id = data.payer_id
          this.temp.sender_id = data.sender_id
          this.temp.recip_id = data.recip_id

          data.common_cargo_weight = 0
          data.common_cargo_volume = 0
          data.common_cargo_amount = 0
          data.cargo_kind_id = 0
          data.cargo_cost = 0
          data.cargo_weight = 0
          data.common_cargo_volume = 0
          data.common_cargo_amount = 0
          data.payer_id = 0
          data.sender_id = 0
          data.recip_id = 0
          data.order_type = 1
          data.sender_loading = false
          data.sender_loading = -1
          data.receiver_loading = false
          data.receiver_loading_floor = -1
          delete data.order_id

          this.defaults = {
            calc_fields: {...data},
            cargo_fields: [],
            agents: [

            ],
            response: def.calc_response
          }
          this.calc = data
          this.cargo = [];
          this.agents = data.agents || [];
          this.response = def.calc_response
        } else {
          this.defaults = _.cloneDeep(def);
          this.calc = def.calc_fields || data.calc_fields;
          this.cargo = data.cargo_fields || [];
          this.agents = data.agents || [];
          this.response = def.calc_response || data.response;
        }

        this.parseResponse();
      })
          .finally(() => {
            // console.log(222, this.sending, this.loaded)
            this.loaded = true;
            
          });
    },
    parseResponse() {
      let data = this.response;
      let options = this.options;
      let calc = this.calc;

      this.getAgent(1);
      this.getAgent(2);
      this.getAgent(3);

      let officesFrom = _.filter(data.city_from_adresses, item => item && _.isNumber(item.id) && item.adress).map(address => ({
        value: address.id,
        title: address.adress
      }));
      options.officesFrom = _.orderBy(officesFrom, ['title'], this.calc.city_from == 'Москва' ? ['desc'] : ['asc']);

      let officesTo = _.filter(data.city_to_adresses, item => item && _.isNumber(item.id) && item.adress).map(address => ({
        value: address.id,
        title: address.adress
      }));
      options.officesTo = _.orderBy(officesTo, ['title'], this.calc.city_to == 'Москва' ? ['desc'] : ['asc']);

      if (!options.officesFrom.length && !calc.cargo_pickup) {
        calc.cargo_pickup = true;
      }
      if (!options.officesTo.length && !calc.cargo_delivery) {
        calc.cargo_delivery = true;
      }

      if (!calc.cargo_pickup && !_.find(options.officesFrom, item => item.value == calc.cargo_pickup_id)) {
        calc.cargo_pickup_id = options.officesFrom[0].value;
      }

      // console.log(options.officesTo, calc.cargo_delivery_id);
      if (!calc.cargo_delivery && !_.find(options.officesTo, item => item.value == calc.cargo_delivery_id)) {
        calc.cargo_delivery_id = options.officesTo[0].value;
      }

      options.datesFrom = _.map(data.city_from_dates, date => moment(date));
      options.datesTo = _.map(data.city_to_dates, date => moment(date));
      // console.log(1, options.datesFrom)
      // console.log(2, options.datesTo)

      if (options.datesFrom.length && (!calc.cargo_pickup_date || (calc.cargo_pickup_date && !_.find(options.datesFrom, date => date.isSame(moment(calc.cargo_pickup_date), 'd'))))) {
        calc.cargo_pickup_date = moment(options.datesFrom[0]).toDate() || moment().toDate();
      }

      if (needResetDate) {
        needResetDate = false;
        calc.cargo_pickup_date = moment().toDate();
        calc.cargo_delivery_date = moment().toDate();
      }

      if (options.datesTo.length && (!calc.cargo_delivery_date || (calc.cargo_delivery_date && !_.find(options.datesTo, date => date.isSame(moment(calc.cargo_delivery_date), 'd'))))) {
        calc.cargo_delivery_date = moment(options.datesTo[0]).toDate() || moment().toDate();
      }

      if (calc.express_service && (this.isStartDateChanged || !this.isExpressApplied)) {
        if (moment(calc.cargo_delivery_date).isAfter(options.datesTo[0]) || moment(calc.cargo_delivery_date).isBefore(options.datesTo[0])) calc.cargo_delivery_date = moment(options.datesTo[0]).toDate()
        this.isStartDateChanged = false
      }

      if (calc.express_service && !this.isExpressApplied) {
        if (moment(calc.cargo_pickup_date).isAfter(options.datesFrom[0]) || moment(calc.cargo_pickup_date).isBefore(options.datesFrom[0])) {
          calc.cargo_pickup_date = moment(options.datesFrom[0]).toDate()
          this.isStartDateChanged = true
        }
        this.isExpressApplied = true
      } else if (!calc.express_service && this.isExpressApplied) this.isExpressApplied = false

      if (this.calc.calc_type == 'extra' && typeof data.is_overall == 'string' && data.is_overall.length > 0) {
        let flags = data.is_overall.split('');
        let len = flags.length;
        for (let i = 0; i < len; i++) {
          let cargo = this.cargo[i];
          if (cargo) {
            cargo.is_dimensional = !!parseInt(flags[i]);
          }
        }
      }

      if (!data.is_calc_express && calc.express_service) {
        calc.express_service = false
      }

      options.timeFrom = _.map(data.city_from_times, value => ({value, title: value}));
      options.timeTo = _.map(data.city_to_times, value => ({value, title: value}));
    },
    createOrder() {
      if (!this.sending) {
        this.sending = true;
        let vObject = validateObject(this, this.$v);
        // console.log(JSON.parse(JSON.stringify(this.$v)))
        let data = _.merge({}, _.pick(this.defaults, ['calc_fields']), {
          calc_fields: _.extend(_.cloneDeep(vObject.calc), {
            city_from: _.get(_.find(this.options.towns, town => town.town_id == this.calc.city_from_id), 'town_name') || '',
            city_to: _.get(_.find(this.options.towns, town => town.town_id == this.calc.city_to_id), 'town_name') || '',
            cargo_pickup_date: moment(this.calc.cargo_pickup_date).format('YYYY-MM-DD'),
            cargo_delivery_date: moment(this.calc.cargo_delivery_date).format('YYYY-MM-DD')
          }),
          cargo_fields: _.cloneDeep(vObject.cargo) || [],
          agent_fields: _.cloneDeep(vObject.agents || []).map(agent => _.extend({}, AGENT, agent)),
          order_id: this.order_id || ''
        });
        // console.log(_.find(vObject.agents, agent => agent.agent_role == 1))
        // console.log(_.find(data.agent_fields, agent => agent.agent_role == 1))
        // Т.к. Эндпоинт обсирается с подливой при наличие посторонних полей, было принято решение оставлять только обязательные поля
        const filteredCalc_fields = {}
        necessaryCalcFieldsList.forEach(key => filteredCalc_fields[key] = data.calc_fields[key])
        data.calc_fields = filteredCalc_fields

        api.createOrder(data).then(response => {
          // console.log(response);
          let data = response.data;
          // console.log(data);
          if (_.isPlainObject(data)) {
            if (data.location) {
              window.location = `/${data.location}/?order_id=${data.order_id}`;
              this.$store.commit('saveCalc', null);
            }
            if (data.error) {
              this.orderError = data.error;
            } else {
              this.orderError = null;
            }
          }

        }).finally(() => {
          // console.log(this.sending, this.loaded)
          this.sending = false;
          if (!this.loaded) this.loaded = true
        });
      }
    },
    openBar() {
      document.body.classList.add('noscroll');
      this.showBill = true;
      this.scrollInfoData();
      let hf = document.querySelector('.header-foot');
      if (hf) {
        hf.classList.add('hide');
      }
    },
    closeBar() {
      this.showBill = false;
      document.body.classList.remove('noscroll');
      let hf = document.querySelector('.header-foot');
      if (hf) {
        hf.classList.remove('hide');
      }
    },
    attemptToClose($event) {
      if ($event.target.classList.contains('total-cont')) {
        this.closeBar();
      }
    },
    scrollInfoData() {
      let $el = document.querySelector('.info-data');
      let $cont = document.querySelector('.total-cont');
      let $top = document.querySelector('.grads .top');
      let $bottom = document.querySelector('.grads .bottom');
      if ($el) {
        let h = $el.offsetHeight;
        let sh = $el.scrollHeight;
        let sy = $el.scrollTop;
        let smax = sh - h;

        let opacityTop = 0;
        let opacityBottom = 0;
        if (smax > 0) {
          opacityTop = Math.floor(sy / 20 * 100) / 100;
          if (opacityTop > 1) opacityTop = 1;

          opacityBottom = Math.floor((smax - sy) / 20 * 100) / 100;
          if (opacityBottom > 1) opacityBottom = 1;
        }
        $top.style.opacity = opacityTop;
        $bottom.style.opacity = opacityBottom;
      }
    },
    openSavedRequests() {
      window.location.href = '/request/saved';
    },
    saveRequest() {
      let vObject = validateObject(this, this.$v);
      // console.log(vObject.calc.cargo_kind_id);
      let request = _.merge({}, _.omit(this.defaults, ['calc_response']), {
        calc_fields: _.extend(_.cloneDeep(vObject.calc), {
          city_from: _.get(_.find(this.options.towns, town => town.town_id == this.calc.city_from_id), 'town_name') || '',
          city_to: _.get(_.find(this.options.towns, town => town.town_id == this.calc.city_to_id), 'town_name') || '',
          cargo_pickup_date: moment(this.calc.cargo_pickup_date).format('YYYY-MM-DD'),
          cargo_delivery_date: moment(this.calc.cargo_delivery_date).format('YYYY-MM-DD'),
          cargo_kind_id: vObject.calc.cargo_kind_id == -100 ? 0 : vObject.calc.cargo_kind_id
        }),
        cargo_fields: _.cloneDeep(vObject.cargo) || [],
        agents: _.cloneDeep(vObject.agents),
        calc_response: _.omit(this.response, ['TB response', 'TB request'])
      });
      api.saveCalc(request)
          .then(({data}) => {
            // console.log(data);
            window.location.href = '/request/saved';
          });
    },
    getSpec() {
      window.open('/feedback?check', '_blank');
    },
    loadAgents() {
      if (this.$viewer.logged) {
        api.getUserAgentsByTown(this.calc.city_from_id, this.calc.city_to_id)
            .then(({data}) => {
              let SenderList = _.map(_.get(data, 'SenderList'), item => {
                return {
                  title: item.agent_name,
                  value: item.agent_id,
                  item: item
                }
              });
              SenderList.unshift({title: 'Новый', value: 0});
              this.options.senders = SenderList;
              // this.options.senders = this.options.payers;

              let ReceiverList = _.map(_.get(data, 'ReceiverList'), item => {
                return {
                  title: item.agent_name,
                  value: item.agent_id,
                  item: item
                }
              });
              ReceiverList.unshift({title: 'Новый', value: 0});
              this.options.receivers = ReceiverList;
              // this.options.receivers = this.options.payers;

              // костыль для фикса копирования заявки
              if(this.options.payers?.length > 1 && this.temp?.payer_id) {
                this.calc.payer_id = this.temp.payer_id
                this.temp.payer_id = null
              }

              if(this.options.receivers?.length > 1 && this.temp?.recip_id) {
                this.calc.recip_id = this.temp.recip_id
                this.temp.recip_id = null
              }
              if(this.options.senders?.length > 1 && this.temp?.sender_id) {
                this.calc.sender_id =  this.temp.sender_id
                this.temp.sender_id = null
              }
            });
      }
    },
    parseSaved(saved, type) {
      let savedData = _.cloneDeep(saved);
      let demo = _.cloneDeep(DEMO);

      this.defaults = _.cloneDeep(saved);

      if (type === 'api') {
        this.calc = _.get(savedData, 'calc_fields') || demo.calc_fields;
      }
      this.cargo = _.get(savedData, 'cargo_fields') || demo.cargo_fields || [];
      this.agents = _.get(savedData, 'agents') || demo.agents || [];
      this.response = _.get(savedData, 'calc_response') || demo.response;
    }
  },
  mounted() {
    // this.$store.commit('saveCalc', null)
    let matchedId = window.location.pathname.match(/^\/calculator\/(.*)/);
    let requestId = _.get(matchedId, '[1]');

    this.debouncedSaveCalc = _.debounce(() => {
      this.saveCalcRequest();
    }, 500);
    this.loadAgentsDebounced = _.debounce(() => {
      this.loadAgents();
    }, 500);

    const tempData = {}

     const getUserAgent = new Promise( (resolve, reject) => {

       this.$viewer.loadUser()
        .then(() => {
          if (this.$viewer.logged) {
            
            _.merge(this.blocks, {
              calc: {
                show: false,
                filled: false
              },
              payer: {
                show: true,
                filled: true
              }
            });
            // console.log('api', api)
            api.getUserAgents()
                .then(({data}) => {
                  console.log(data)
                  let list = _.map(_.get(data, 'PayerList'), item => {
                    return {
                      title: item.agent_name,
                      value: item.agent_id,
                      item: item
                    }
                  });
                  list.unshift({
                    title: 'Новый',
                    value: 0,
                    item: {
                      agent_contact: "",
                      agent_document_number: "",
                      agent_document_series: "",
                      agent_document_type_id: null,
                      agent_email: "",
                      agent_inn: "",
                      agent_is_informing_mail: false,
                      agent_is_informing_sms: false,
                      agent_kpp: "",
                      agent_name: "",
                      agent_phone: "",
                      agent_extra_phone: "",
                      agent_role: null,
                      agent_type: null,
                      type_pay: null
                    }
                  });
                  tempData.list = list
                  tempData.payers = data.PayerList
                });
                
          }
          resolve()
        });
     })
  
    getUserAgent.then(()=>{
    Promise.all([
      this.$store.dispatch('towns'),
      this.$store.dispatch('cargoKinds'),
      this.$store.dispatch('docTypes')
    ]).then(([towns, cargoKinds, docTypes]) => {
      this.options.towns = _.keyBy(towns, 'town_id');
      this.options.citiesAll = _.map(towns, this.parseCity);
      this.options.cities = _.take(this.options.citiesAll, 1000);
      this.options.cargoKinds = _.map(cargoKinds, item => ({value: item.cargo_kind_id, title: item.name}));
      this.options.docTypes = _.map(docTypes, item => ({value: item.tb_id, title: item.name}));
      if (!requestId) {
        this.load().then(() => {
          let savedData = this.$store.getters.getCalc;
          if (savedData) {
            this.parseSaved(savedData, 'store');
          }

          this.options.payers = tempData.list;
          let def_payer
          let payers = tempData.payers;
          if (payers && payers.length) def_payer = _.find(payers, item => item.default_payer === 'ДА')
          if (def_payer) {
            delete def_payer.default_payer
            this.calc.payer_id = def_payer.agent_id
            this.check('payer')
          }
          if (this.options.payers && this.options.payers.length > 1 && !def_payer) {
            this.calc.payer_id = null
            // console.log('set payer id null')
          }

          this.debouncedCalculate = _.debounce(() => {
            // console.log('calculate')
            this.calculate();
          }, 500);
        });
      } else {
        api.getCalc(requestId)
            .then(response => {
              this.saved = true;
              this.options.payers = tempData.list;
              this.parseSaved(response.data, 'api');
              this.parseResponse();
              this.calculate();

              this.debouncedCalculate = _.debounce(() => {
                this.calculate();
              }, 500);
            })
            .finally(() => {
              // console.log(111, this.sending, this.loaded)
              this.loaded = true;
            });
      }
    });
    
    }) // end promise 

    let wh = window.outerHeight;
    let ww = window.innerWidth;
    let offsetYLimit = 20;
    let initialY;
    window.addEventListener('resize', () => {
      ww = window.innerWidth;
    });
    window.addEventListener('scroll', event => {
      let info = document.querySelector('.info-cont');
      let footer = document.querySelector('.footer');
      let cont = document.querySelector('.container > .row');

      let header = document.querySelector('#header');

      let ty = 0;
      if (info && footer && cont && ww >= 768) {
        let infoRect = info.getBoundingClientRect();
        let contRect = cont.getBoundingClientRect();
        let hRect = header.getBoundingClientRect();
        if (contRect.top < hRect.bottom) {
          ty = -contRect.top + hRect.height;
          if (ty > contRect.height - infoRect.height) {
            ty = contRect.height - infoRect.height;
          }
        }
      }
      if (info) {
        info.style.transform = `translate(0, ${ty}px)`;
      }
    });
  },
  beforeDestroy() {
    this.closeBar();
  }
}

</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>