<template lang="pug">
  div
    back.mt-3.text-gray-300(name="claims-list")
      | Назад к списку
    //- preloader.mt-5(v-if="loading")
    template
      .f-18.fw-500.text-gray-800.mt-2
        | Создание документа
      c-row.mt-3
        b(v-if="claim.ProblemType == 1")
          | Cрок ответа на обращения 1 рабочий день.  Ответ предоставляется в  пн-пт 9.00-18.00
        b(v-if="claim.ProblemType == 2")
          | Спасибо за Ваше обращение! Ваш запрос будет обработан в течение рабочего дня с 9.00 до 18.00
          br
          a(href="https://www.skif-cargo.ru/documents/4")
            | Образцы заявлений
        b(v-if="claim.ProblemType == 3")
          | Решение по претензии будет предоставлено при наличии документов, подтверждающих стоимость и размер ущерба
          br
          a(href="https://www.skif-cargo.ru/documents/8", target="_blank")
            | Подсказка по документам для претензии и бланки для скачивания 
        
        
      c-row.mt-3
        c-col.col-12.col-md-6.col-lg-4
          c-select(placeholder="Тип обращения", :options='options.claimTypes', v-model="claim.ProblemType")
            option(value=1) Обращение
            option(value=2) Заявление на изменение данных
            option(value=3) Претензия
            c-input.mt-2.mt-md-0.hidden(placeholder="Номер заявки", type="hidden", v-model="claim.OrderNo")
          c-input.mt-2.mt-md-0(placeholder="Номер заявки", v-model="claim.OrderShortNo", disabled="disabled")
          c-textarea(placeholder="Описание проблемы", v-model="claim.ILDescription")
          c-input.mt-2.mt-md-0(placeholder="E-mail", v-model="claim.Email")
          c-input.mt-2.mt-md-0(placeholder="Телефон", v-model="claim.Phone", v-mask="{mask: '+99999999999'}")

      c-row.mt-3
        c-col.col-12.col-md-6.col-lg-8(v-if="fileTypes.length")
          b
            | Прикрепляемые файлы
            //- template(slot="fileTypes", slot-scope="{fileTypes}")
          c-row(v-for='(group, group_index) in fileTypesDict', :key='index')
            c-row.mt-3(:id="`files_${group_index}`", :class="{ hidden: claim.ProblemType != group_index }" v-for='(item, index) in group', :key='index', v-if="fileTypes.length")
              .content
                label.col-lg-8
                  span.red(v-if="item.MustHave == 1")
                    | {{item.ATName}} 
                  span(v-else)
                    | {{item.ATName}}
                  br
                  input.mt-2.mt-md-0(:id="`file_${item.ATID}`", type="file", @change="uploadFile(item.ATID)", :ref="`file_${item.ATID}`")
      c-row.mt-3
        c-col.col-12.col-md-6.col-lg-4
          .alert.alert-danger.mb-0.f-12.d-block.mt-3(v-if="error")
            | {{error}}
            //- $v.claim.$invalid ||
          c-button.mt-4.w-100.w-md-auto(@click="save", :disabled="saving") 
            | Сохранить
</template>

<script>
  import _ from 'lodash';
  import api from '@/js/api';
  import {required, email, helpers, integer, requiredIf} from 'vuelidate/lib/validators';
  import validateObject from '@/js/core/validation-to-object';

  const notEmpty = value => !!value;
  const phoneRegExp = /^[+]?\d{11,12}$/;
  const phoneCheck = value => phoneRegExp.test(('' + value || '').replace(/[\!\-\(\)\{\} ]/g, ''));

  const validateFiles = (model) => {
    // fucking hardcode

    let result = true

    _.each(model.fileTypes, function(fileType, i) {
      if(fileType.MustHave == 1 && !model.files[fileType.ATID]) {
        result = false
        return result
      }
    })

    return result
  }

  const skip = (check, validators) => {
    let params = {skip: {value: false}};
    return helpers.withParams(params, function(value, model) {
      let result;
      if (check.call(this, value, model)) {
        params.skip.value = false;
        if (_.isFunction(validators)) {
          return validators.call(this, value, model)
        }
        if (_.isPlainObject(validators) || _.isArray(validators)) {
          return _.reduce(validators, (s, validator) => s && validator.call(this, value, model), true);
        }
        return false;
      }
      params.skip.value = true;
      return true;
    });
  };
  const keep = () => true;


  export default {
    data: () => ({
      loading: true,
      saving: false,
      error: null,
      options: {
        claimTypes: [
          {
            value: 1,
            title: 'Обращение'
          },
          {
            value: 2,
            title: 'Заявление на изменение данных'
          },
          {
            value: 3,
            title: 'Претензия'
          },
        ],
      },
      claim: {
        OrderNo: null,
        OrderShortNo: null,
        ProblemType: null,
        ILDescription: null,
        Email: null,
        Phone: null,
      },
      files: {
      },
      fileTypes: {},
      fileTypesDict: {}
    }),
    watch: {
      'claim.ProblemType': function(value) {
        this.fileTypes = this.fileTypesDict[value]
      }
    },
    provide() {
      return {
        validation: this.$v
      }
    },
    validations() {
      let validation = {
        claim: {
          OrderNo: {required,integer},
          ProblemType: {required},
          ILDescription: {required},
          Email: {required, email},
          Phone: {phoneCheck},
        },
        // files: {fileCheck}
      }

      return validation
    },
    methods: {
      uploadFile(fileTypeId) {
        this.files[fileTypeId] = this.$refs["file_"+fileTypeId][0].files[0];
      },
      save() {

        if (!this.saving) {
          this.saving = true;
          
          let vObject = validateObject(this, this.$v);

          let claim = vObject

          let fileCheck = validateFiles(this)

          if(this.$v.$invalid || !fileCheck) {
            this.error = "Заполните все необходимые поля"
            this.saving = false
          } else {
            this.error = null

            api.createClaim(claim, this.files)
              .then(({data}) => {
                this.$notify({
                  group: 'foo',
                  clean: true
                })
                if (data.status == 200 && !data.error) {

                  this.$router.push({name: 'claims-list'});
                }
                if (data.error) {
                  this.error = data.error;
                }
              })
              .finally(() => {
                this.saving = false;
              });
          }
        }
      }
    },
    beforeCreate(){
      for(let i=1; i<=3; i++){
        api.getClaimsFileTypes(i).then(({data}) => {
          this.fileTypesDict[i] = data
        })
      }
    },
    mounted() {
      
      this.claim.OrderNo = this.$route.query.orderNo
              // OrderNo: ,
      this.claim.OrderShortNo = this.$route.query.orderShortNo
        // OrderShortNo: ,
    }
  };
</script>

<style lang="scss" scoped>
  .red {
    color: #CA1226;
  }
  .hidden {
    display: none
  }
</style>