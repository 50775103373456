var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c("preloader", { staticClass: "mt-3" })
        : [
            _c(
              "c-row",
              { staticClass: "my-2 align-items-center" },
              [
                _vm.pageInfo.itemsCount > 0
                  ? _c("c-col", { staticClass: "col-auto f-14" }, [
                      _vm._v(
                        _vm._s(_vm.pageInfo.itemsCount) +
                          " " +
                          _vm._s(
                            _vm._f("plural")(
                              _vm.pageInfo.itemsCount,
                              _vm.pluralResults
                            )
                          )
                      )
                    ])
                  : _vm._e(),
                _vm.pageInfo.pagesCount > 1
                  ? _c(
                      "c-col",
                      { staticClass: "text-right d-none d-md-block" },
                      [
                        _c("pagination", {
                          attrs: { count: _vm.pageInfo.pagesCount },
                          model: {
                            value: _vm.pageInfo.currentPage,
                            callback: function($$v) {
                              _vm.$set(_vm.pageInfo, "currentPage", $$v)
                            },
                            expression: "pageInfo.currentPage"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mt-3" },
              [
                _vm._t(
                  "list",
                  function() {
                    return [
                      _c("list", {
                        attrs: { items: _vm.items },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var item = ref.item
                                var index = ref.index
                                return _vm._t("default", null, {
                                  item: item,
                                  index: index
                                })
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ]
                  },
                  { items: _vm.items }
                ),
                !_vm.items.length
                  ? [
                      _c("div", { staticClass: "text-center" }, [
                        _vm._v("Список пуст.")
                      ])
                    ]
                  : _vm._e()
              ],
              2
            ),
            _vm.pageInfo.pagesCount > 1
              ? _c(
                  "div",
                  {
                    staticClass: "my-3 shadow-box d-block d-md-none text-center"
                  },
                  [
                    _c("pagination", {
                      attrs: { count: _vm.pageInfo.pagesCount },
                      model: {
                        value: _vm.pageInfo.currentPage,
                        callback: function($$v) {
                          _vm.$set(_vm.pageInfo, "currentPage", $$v)
                        },
                        expression: "pageInfo.currentPage"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }